type Value = number | string | null | undefined

const parseValue = (value: Value): number =>
  parseFloat(value ? value.toString() : '0') / 100

const formatValue = (value: number): string => {
  const [_number, decimals] = Math.abs(value).toFixed(2).toString().split('.')

  const number = _number.split(/(?=(?:...)*$)/).join('.')

  return [number, decimals].join(',')
}

const toCurrency = (value: number): string =>
  `${value < 0 ? '- ' : ''}R$ ${formatValue(value)}`

export const numberToCurrency = (val: Value) => {
  const value = parseValue(val)
  return toCurrency(value)
}

export const numberToCurrencyAriaLabel = (value?: number): string => {
  const val = parseValue(value)
  const currencyValue = formatValue(val).split(',')

  const firstCurrencyValue = `${currencyValue?.[0]} reais`
  const secondCurrencyValue =
    Number(currencyValue?.[1]) !== 0 ? ` e ${currencyValue[1]} centavos` : ''

  return `${val < 0 ? '- ' : ''}${firstCurrencyValue}${secondCurrencyValue}.`
}
