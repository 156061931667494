import { parse } from 'cookie'

import { User } from '@lojinha/context/src/types'

import { beholder } from './beholder'

export const segmentTrackerScript = `
  ! function() {
    var analytics = window.analytics = window.analytics || [];
    if (!analytics.initialize)
      if (analytics.invoked) window.console && console.error && console.error("Segment snippet included twice.");
      else {
          analytics.invoked = !0;
          analytics.methods = ["trackSubmit", "trackClick", "trackLink", "trackForm", "pageview", "identify", "reset", "group", "track", "ready", "alias", "debug", "page", "once", "off", "on", "addSourceMiddleware", "addIntegrationMiddleware", "setAnonymousId", "addDestinationMiddleware"];
          analytics.factory = function(e) {
              return function() {
                  var t = Array.prototype.slice.call(arguments);
                  t.unshift(e);
                  analytics.push(t);
                  return analytics
              }
          };
          for (var e = 0; e < analytics.methods.length; e++) {
              var t = analytics.methods[e];
              analytics[t] = analytics.factory(t)
          }
          analytics.load = function(e, t) {
              var n = document.createElement("script");
              n.type = "text/javascript";
              n.async = !0;
              n.src = "https://cdn.segment.com/analytics.js/v1/" + e + "/analytics.min.js";
              var a = document.getElementsByTagName("script")[0];
              a.parentNode.insertBefore(n, a);
              analytics._loadOptions = t
          };
          analytics.SNIPPET_VERSION = "4.1.0";
          analytics.load("${process.env.NEXT_PUBLIC_SEGMENT_ID}");
      }
  }();
`

const convertToSegmentCurrency = (value: number) => {
  return Number((value / 100).toFixed(2))
}

const convertToSegmentProducts = ({
  items,
  displayCategory,
}: {
  items: Array<{
    sku?: string
    name?: string
    price: number
    quantity?: number
    url?: string
    imageUrl?: string
  }>
  displayCategory?: string
}) =>
  items.map(({ sku, quantity, price, name, url, imageUrl }) => ({
    product_id: sku,
    sku,
    name,
    price: convertToSegmentCurrency(price),
    quantity,
    category: displayCategory,
    url,
    image_url: imageUrl,
  }))

const convertToSegmentUser = (user: User | undefined) => {
  if (!user) {
    return {}
  }

  const splittedName = user.name ? user.name.split(' ') : []

  const firstName = splittedName[0] || ''
  const lastName = splittedName[splittedName.length - 1] || ''

  return {
    email: user.email,
    firstName,
    lastName,
    phoneNumber: user.phoneNumber,
    city: user.city,
    state: user.state,
    country: user.country,
    zipCode: user.zipCode,
    facebookId: user.facebookId,
  }
}

export const getFacebookCookies = () => {
  const stringfiedCookies = process.browser ? document && document.cookie : ''

  const { _fbp: fbp, _fbc: fbc } = parse(stringfiedCookies)

  return { fbp, fbc }
}

export const segmentTrackerSetup = () => {
  // Beholder functions
  beholder.watch('setUser', user => {
    window.analytics.identify(user.id, {
      id: user.id,
      ...convertToSegmentUser(user as User),
    })
  })

  beholder.watch(
    'checkoutInitiated',
    ({ numberOfOrders, cartItems, cartSubTotal, user }) => {
      const segmentCartItems = convertToSegmentProducts({ items: cartItems })

      const { fbp, fbc } = getFacebookCookies()

      window.analytics.track('Checkout Started', {
        products: segmentCartItems,
        value: cartSubTotal && convertToSegmentCurrency(cartSubTotal),
        currency: 'BRL',
        new_buyer: numberOfOrders === 0 ? '1' : '0',
        fbp,
        fbc,
        traits: convertToSegmentUser(user as User),
      })
    }
  )

  beholder.watch('paymentMethodSelected', ({ method, brand }) => {
    window.analytics.track('Payment Info Entered', {
      payment_method: method,
      brand,
    })
  })

  beholder.watch('couponFilled', payload => {
    const event =
      payload.status === 'accepted' ? 'Coupon Applied' : 'Coupon Denied'

    window.analytics.track(event, {
      coupon_name: payload.nameOfCoupon,
      category: payload.category,
      action: 'coupon_filled',
      label: payload.rejectedReason,
    })
  })

  beholder.watch('trackPageView', ({ path, experimentGroup, user }) => {
    const { fbp, fbc } = getFacebookCookies()

    window.analytics.page(path, {
      fbp,
      fbc,
      ...(experimentGroup && { experiment_group: experimentGroup }),
      traits: convertToSegmentUser(user as User),
    })
  })

  beholder.watch(
    'finished',
    ({ orderNumber, items, total, numberOfOrders, user }) => {
      const segmentCartItems = convertToSegmentProducts({ items })
      const { fbp, fbc } = getFacebookCookies()

      const newBuyer = numberOfOrders === 0

      const userFields = convertToSegmentUser(user as User)

      window.analytics.track('Order Completed', {
        order_id: orderNumber,
        eventID: orderNumber.toString(),
        total: convertToSegmentCurrency(total),
        type: 'product',
        products: segmentCartItems,
        currency: 'BRL',
        new_buyer: newBuyer ? '1' : '0',
        fbc,
        fbp,
        traits: userFields,
      })

      window.analytics.track(newBuyer ? 'First Order' : 'Recurring Order', {
        order_id: orderNumber,
        eventID: orderNumber.toString(),
        total: convertToSegmentCurrency(total),
        value: convertToSegmentCurrency(total),
        type: 'product',
        products: segmentCartItems,
        currency: 'BRL',
        new_buyer: newBuyer ? '1' : '0',
        traits: userFields,
      })
    }
  )

  beholder.watch(
    'addToCart',
    ({ action, item, isFirstBuy, experimentGroup, user }) => {
      const event = action === 'add' ? 'Product Added' : 'Product Removed'

      const { fbp, fbc } = getFacebookCookies()

      window.analytics.track(event, {
        product_id: item.id,
        sku: item.id,
        name: item.name,
        category: item.category,
        price: (item.price / 100).toFixed(2),
        currency: 'BRL',
        quantity: item.quantity,
        new_buyer: isFirstBuy ? '1' : '0',
        fbc,
        fbp,
        ...(experimentGroup && { experiment_group: experimentGroup }),
        traits: convertToSegmentUser(user as User),
      })
    }
  )

  beholder.watch('cartCreated', ({ item, isFirstBuy }) => {
    window.analytics.track('Cart Created', {
      product_id: item.id,
      sku: item.id,
      name: item.name,
      category: item.category,
      price: (item.price / 100).toFixed(2),
      value: (item.price / 100).toFixed(2),
      currency: 'BRL',
      quantity: item.quantity,
      new_buyer: isFirstBuy ? '1' : '0',
    })
  })

  beholder.watch('listView', ({ id, name, items }) => {
    const segmentCartItems = convertToSegmentProducts({
      items,
      displayCategory: name,
    })

    window.analytics.track('Product List Viewed', {
      list_id: id,
      category: name,
      products: segmentCartItems.slice(0, 4),
    })
  })

  beholder.watch(
    'productDetailView',
    ({ id, name, listName, positionInList, price, isAvailable, user }) => {
      const { fbp, fbc } = getFacebookCookies()

      window.analytics.track('Product Viewed', {
        product_id: id,
        product_name: name,
        list: listName,
        position_in_list: positionInList,
        product_price: convertToSegmentCurrency(price),
        label: isAvailable ? '1' : '0',
        fbc,
        fbp,
        traits: convertToSegmentUser(user as User),
      })
    }
  )
}
