import React, { FC } from 'react'
import { theme, FontSizeProps } from '../../../../theme'

export const UpBrasil: FC<{
  size?: FontSizeProps
}> = ({ size }) => {
  return (
    <svg
      width={theme.fontSizes[size!]}
      height={theme.fontSizes[size!]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M21 3H3V21H21V3Z" fill="#F59100" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.90168 13.6186C9.9431 13.6186 10.1463 12.9049 10.1463 11.9778V7.11105L11.636 6.78212V12.5315C11.636 13.9349 10.5768 15.055 8.83437 15.0563C7.49831 15.0563 6.1178 14.2981 6.1178 12.3181V7.11105L7.61134 6.78212V12.1022C7.61134 12.7804 7.73581 13.6186 8.90168 13.6186ZM13.7927 9.45805C14.2656 9.12568 14.8301 8.94819 15.4081 8.95004L15.4145 8.96147C17.0338 8.96147 18.347 10.328 18.347 12.0095C18.347 13.691 17.0338 15.0576 15.4145 15.0576C14.8355 15.0579 14.2703 14.8806 13.7952 14.5496V16.5816L12.3512 16.9105V8.95766L13.7927 8.60968V9.45805ZM13.6568 11.9994C13.6568 12.9392 14.3578 13.7431 15.3192 13.7431C16.2781 13.7431 16.983 12.9379 16.983 11.9994C16.983 11.0608 16.2845 10.2569 15.3192 10.2569C14.354 10.2569 13.6568 11.0595 13.6568 11.9994Z"
        fill="white"
      />
    </svg>
  )
}

UpBrasil.defaultProps = {
  size: 'x3',
}
