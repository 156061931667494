type BreakpointsEnum = 'sm' | 'md' | 'lg'

type WindowSizes = number[] & { [key in BreakpointsEnum]: number }

export const windowSizesPx = [768, 1264, 1600] as WindowSizes
windowSizesPx.sm = windowSizesPx[0]
windowSizesPx.md = windowSizesPx[1]
windowSizesPx.lg = windowSizesPx[2]

export const breakpoints = windowSizesPx.map((bp: number) => `${bp}px`)

export const media = {
  above(size: BreakpointsEnum | number) {
    return `@media (min-width: ${windowSizesPx[size]}px)`
  },
  below(size: BreakpointsEnum | number) {
    const idx = windowSizesPx.indexOf(windowSizesPx[size]!)
    const breakpoint = windowSizesPx[idx - 1] || windowSizesPx[0]
    return `@media (max-width: ${breakpoint - 1}px)`
  },
}
