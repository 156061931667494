import React, { FC } from 'react'
import { theme, FontSizeProps } from '../../../../theme'

export const Discover: FC<{
  size?: FontSizeProps
}> = ({ size }) => {
  return (
    <svg
      width={theme.fontSizes[size!]}
      height={theme.fontSizes[size!]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.64926 12.5165C3.45272 12.6952 3.19732 12.7731 2.79309 12.7731H2.62518V10.6388H2.79309C3.19732 10.6388 3.44269 10.7116 3.64926 10.8999C3.86563 11.0937 3.99575 11.3942 3.99575 11.7033C3.99575 12.0132 3.86563 12.3228 3.64926 12.5165ZM2.91838 10.092H2V13.3194H2.91355C3.39932 13.3194 3.75014 13.2042 4.05796 12.9468C4.4239 12.6422 4.64026 12.183 4.64026 11.708C4.64026 10.7555 3.93304 10.092 2.91838 10.092Z"
        fill="#212D34"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.92813 13.3195H5.55381V10.092H4.92813V13.3195Z"
        fill="#212D34"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.08366 11.3305C6.70818 11.1907 6.59801 11.0986 6.59801 10.9241C6.59801 10.7209 6.79443 10.5664 7.06408 10.5664C7.25145 10.5664 7.40561 10.644 7.56856 10.8278L7.89596 10.3965C7.62693 10.1597 7.30511 10.0387 6.95342 10.0387C6.38586 10.0387 5.953 10.4352 5.953 10.9634C5.953 11.4081 6.1545 11.6358 6.74213 11.8485C6.98713 11.9355 7.11179 11.9934 7.17462 12.0323C7.29965 12.1144 7.36223 12.2308 7.36223 12.3663C7.36223 12.6278 7.15566 12.8216 6.87646 12.8216C6.57806 12.8216 6.33766 12.6713 6.19366 12.391L5.78943 12.7827C6.07767 13.2085 6.4239 13.3971 6.90001 13.3971C7.5501 13.3971 8.00625 12.9621 8.00625 12.3374C8.00625 11.8245 7.79534 11.5925 7.08366 11.3305Z"
        fill="#212D34"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.20358 11.708C8.20358 12.6566 8.94389 13.3922 9.89659 13.3922C10.1659 13.3922 10.3966 13.3389 10.681 13.2041V12.4631C10.4309 12.715 10.2094 12.8166 9.92571 12.8166C9.29557 12.8166 8.84834 12.3569 8.84834 11.7032C8.84834 11.0837 9.3097 10.5949 9.89659 10.5949C10.195 10.5949 10.4209 10.702 10.681 10.958V10.2173C10.4064 10.0772 10.1806 10.019 9.91134 10.019C8.96347 10.019 8.20358 10.7696 8.20358 11.708Z"
        fill="#212D34"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6418 12.2599L14.7865 10.092H14.1029L15.4641 13.4023H15.8007L17.1864 10.092H16.5082L15.6418 12.2599Z"
        fill="#212D34"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4694 13.3195H19.2437V12.7731H18.0945V11.9019H19.2014V11.3552H18.0945V10.6389H19.2437V10.092H17.4694V13.3195Z"
        fill="#212D34"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4657 11.5779H20.2829V10.6004H20.4756C20.8654 10.6004 21.0772 10.7646 21.0772 11.0787C21.0772 11.4032 20.8654 11.5779 20.4657 11.5779ZM21.7209 11.0448C21.7209 10.4406 21.3073 10.0919 20.5858 10.0919H19.658V13.3194H20.283V12.0229H20.3646L21.2306 13.3194H22L20.9902 11.9598C21.4614 11.8633 21.7209 11.539 21.7209 11.0448Z"
        fill="#212D34"
      />
      <g filter="url(#filter0_i)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.298 11.7169C14.298 12.6652 13.534 13.4339 12.5916 13.4339C11.6492 13.4339 10.8853 12.6652 10.8853 11.7169C10.8853 10.7686 11.6492 10 12.5916 10C13.534 10 14.298 10.7686 14.298 11.7169Z"
          fill="url(#paint0_linear)"
        />
      </g>
      <defs>
        <filter
          id="filter0_i"
          x="10.8853"
          y="10"
          width="3.41266"
          height="3.43386"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.1" dy="0.1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.314068 0 0 0 0 0.00146228 0 0 0 0 0.00146228 0 0 0 0.5 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
        </filter>
        <linearGradient
          id="paint0_linear"
          x1="13.5"
          y1="13"
          x2="12"
          y2="10.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFA700" />
          <stop offset="0.648283" stopColor="#E75A10" />
        </linearGradient>
      </defs>
    </svg>
  )
}

Discover.defaultProps = {
  size: 'x3',
}
