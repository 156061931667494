import dayjs from 'dayjs'
import { CookieSerializeOptions, serialize } from 'cookie'

export const clearCookies = () => {
  const stringfiedCookies = document.cookie.split('; ')

  stringfiedCookies
    .filter(cookie => !cookie.match(/^(_experiment\.|_login_modal)/))
    .forEach(cookie => {
      const currentCookie = cookie.split('=')[0]
      const isSecureToken = process.env.NEXT_PUBLIC_NODE_ENV === 'production'

      const cookieOptions: CookieSerializeOptions = {
        sameSite: 'lax',
        secure: isSecureToken,
        path: '/',
        expires: dayjs().add(-180, 'day').toDate(),
      }

      const newCookie = serialize(currentCookie, '', cookieOptions)

      document.cookie = newCookie
    })
}
