import * as Sentry from '@sentry/nextjs'

import { NextPageContext } from 'next'

export type SentryError = Error & {
  errorInfo?: React.ErrorInfo | Record<string, any>
  tags?: Record<string, any>
  level?: any
}

type SentryOptions = {
  ctx?: NextPageContext
  fingerprint?: string
}

export const captureException = (
  err: SentryError,
  _options?: SentryOptions
) => {
  Sentry.captureException(err)
}
