import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { Variants_Enum } from '@lojinha/experiment'

export function injectExperimentsLink(
  apolloClient: ApolloClient<NormalizedCacheObject>,
  experimentsGetter: () => Record<string, Variants_Enum>
) {
  apolloClient.setLink(
    setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          ...experimentsGetter(),
        },
      }
    }).concat(apolloClient.link)
  )
}
