import { useEffect, useState } from 'react'

export const useDebounce = (value: string, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value)
  const [debouncing, setDebouncing] = useState(false)

  useEffect(() => {
    setDebouncing(true)

    const handler = setTimeout(() => {
      setDebouncedValue(value)
      setDebouncing(false)
    }, delay)

    return () => clearTimeout(handler)
  }, [value])

  return { debouncedValue, debouncing }
}
