import { TypePolicies } from '@apollo/client/cache'

const CMSTypePolicies: TypePolicies = {
  CMSContainer: {
    keyFields: false,
  },
  CMSComponentContainerBanner: {
    keyFields: false,
  },
  CMSComponentHighlightStoryHighlightStories: {
    keyFields: false,
  },
}

export const typePolicies: TypePolicies = {
  Query: {
    fields: {
      viewer: {
        merge(existing, incoming, { mergeObjects }) {
          return mergeObjects(existing, incoming)
        },
      },
    },
  },
  Cart: {
    fields: {
      items: {
        merge(existing, incoming, { mergeObjects }) {
          return mergeObjects(existing, incoming)
        },
      },
      billing: {
        merge(existing, incoming, { mergeObjects }) {
          return mergeObjects(existing, incoming)
        },
      },
    },
  },
  SaleCart: {
    fields: {
      billing: {
        merge(existing, incoming, { mergeObjects }) {
          return mergeObjects(existing, incoming)
        },
      },
      deliverySettings: {
        merge(existing, incoming, { mergeObjects }) {
          return mergeObjects(existing, incoming)
        },
      },
    },
  },
  ...CMSTypePolicies,
}
