import React, { FC } from 'react'
import { theme, FontSizeProps } from '../../../../theme'

export const Mastercard: FC<{
  size?: FontSizeProps
}> = ({ size }) => {
  return (
    <svg
      width={theme.fontSizes[size!]}
      height={theme.fontSizes[size!]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="9.02612"
        y="7.28298"
        width="5.95047"
        height="9.43304"
        fill="#FF5F00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.63891 12.0003C9.63741 10.1599 10.5079 8.42094 11.9995 7.28462C9.46547 5.35169 5.82647 5.63324 3.63992 7.93139C1.45336 10.2295 1.45336 13.7727 3.63992 16.0709C5.82647 18.369 9.46547 18.6506 11.9995 16.7177C10.5074 15.581 9.63688 13.8413 9.63891 12.0003Z"
        fill="#EB001B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 12.0003C21.9999 14.2971 20.6488 16.3922 18.5203 17.3959C16.3919 18.3995 13.8598 18.1355 11.9996 16.716C13.4906 15.5787 14.3611 13.84 14.3611 11.9995C14.3611 10.1589 13.4906 8.42027 11.9996 7.28298C13.8598 5.86346 16.3919 5.59947 18.5203 6.60314C20.6488 7.6068 21.9999 9.70191 22 11.9987V12.0003Z"
        fill="#F79E1B"
      />
    </svg>
  )
}

Mastercard.defaultProps = {
  size: 'x3',
}
