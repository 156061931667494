import Head from 'next/head'
import { Router } from 'next/router'
import React, { useEffect } from 'react'
import { ThemeProvider } from 'styled-components'

import { beholder, Trackers } from '@lojinha/beholder'
import { ContextProvider } from '@lojinha/context'
import { GlobalStyle, theme } from '@lojinha/design-system'
import '@lojinha/design-system/dist/assets/base.css'
import { useExperiment } from '@lojinha/experiment/src/hook'
import { RemoteConfigSetup } from '@lojinha/firebase'
import {
  extractExperimentVariantsFromPath,
  extractExperimentVariantsFromRequest,
  getAddressFromUser,
  getDistributionCenterFromUser,
  getProfileFromUser,
  IS_SERVER,
} from '@lojinha/helpers'
import {
  ApolloProvider,
  injectExperimentsLink,
  User,
  withApolloClient,
} from '@lojinha/palantir'
import {
  AppWithApolloCtx,
  AppWithApolloProps,
} from '@lojinha/palantir/src/apolloTypes'
import { captureException, SentryError } from '@lojinha/sentry'
import { Zendesk } from '@lojinha/zendesk'
import { VIEWER_CONTEXT } from '../core/queries'
import SetupVitrineContext from '../core/setup-vitrine-context'
import { t } from '../dictionary'

export type VitrineInitialProps = {
  user?: User
  authToken: string | null
  userAgent?: string
  isMobileUserAgent?: boolean
  nearestDistributionCenterByIp?: string
  customIp?: string | string[]
  userExperimentVariants: Record<string, string>
}

const routerOnChangeEvent = (
  experimentGroup: string,
  user: User | undefined
) => {
  const userAddress = user ? getAddressFromUser(user) : undefined

  // Shot page view event
  beholder.shot('trackPageView', {
    path: window.location.pathname,
    experimentGroup,
    ...(user
      ? {
          user: {
            id: user.id,
            email: user.email,
            name: user.name || '',
            phoneNumber: user.phoneNumber,
            city: userAddress?.city,
            state: userAddress?.state,
            country: userAddress?.country,
            zipCode: userAddress?.zipCode,
          },
        }
      : {}),
  })
}

export const VitrinePageApp = ({
  Component,
  apolloClient,
  pageProps,
  appProps,
}: AppWithApolloProps<VitrineInitialProps>) => {
  const { authToken, user, userExperimentVariants } = appProps
  const experiment = useExperiment(userExperimentVariants)

  try {
    injectExperimentsLink(apolloClient, experiment.getUserExperiments)
  } catch (error) {
    console.error('Failed to inject experiments link:', error)
  }

  useEffect(() => {
    if (authToken && !user) {
      return
    }

    Router.events.on('routeChangeComplete', () =>
      routerOnChangeEvent(experiment.experimentGroup, user)
    )
    return () => {
      Router.events.off('routeChangeComplete', () =>
        routerOnChangeEvent(experiment.experimentGroup, user)
      )
    }
  }, [experiment.experimentGroup, user, authToken])

  return (
    <ThemeProvider theme={theme}>
      <Head>
        <link rel="manifest" href="/manifest.json" />
        <link rel="shortcut icon" href="/favicon.png" />
        <link href="https://www.livup.com.br" rel="canonical" />
        <link rel="preconnect" href="https://www.google.com" />
        <link
          rel="preconnect"
          href="https://www.gstatic.com"
          crossOrigin="true"
        />

        <title>{t.title}</title>
        <meta name="description" content={t.metaDescription} />

        {/* Facebook Meta Tags */}
        <meta property="og:locale" content="pt_BR" />
        <meta property="og:description" content={t.metaDescription} />
        <meta property="og:title" content={t.metaTitle} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.livup.com.br" />
        <meta
          property="og:logo"
          content="https://livup.imgix.net/site/images/logo-meta.png"
        />
        <meta
          property="og:image"
          content="https://livup.imgix.net/site/images/imagem-compartilhamento-site-liv-up.png"
        />
        <meta
          property="fb:app_id"
          content={process.env.NEXT_PUBLIC_FACEBOOK_APP_ID}
        />
      </Head>

      <ApolloProvider client={apolloClient}>
        <ContextProvider
          isAuthenticated={!!authToken}
          isFirstBuy={user?.isFirstBuy ?? true}
          centerId={getDistributionCenterFromUser(user) || undefined}
          address={getAddressFromUser(user)}
          user={getProfileFromUser(user)}
          referralRewardAmount={
            user?.referralRewardAmount as number | undefined
          }
          userAgent={appProps.userAgent}
          isMobileUserAgent={appProps.isMobileUserAgent}
          experiment={experiment}
        >
          <GlobalStyle />
          <Component {...pageProps} />
          <SetupVitrineContext {...pageProps} />
          <Trackers experiment={experiment} user={user} />
          <Zendesk />
          <RemoteConfigSetup />
        </ContextProvider>
      </ApolloProvider>
    </ThemeProvider>
  )
}

VitrinePageApp.getInitialProps = async (ctx: AppWithApolloCtx) => {
  const { authToken, apolloClient } = ctx
  let user: User | undefined
  let customIp: string | string[] | undefined

  if (IS_SERVER && authToken) {
    try {
      const { data } = await apolloClient.query({
        query: VIEWER_CONTEXT,
      })
      user = data?.viewer.user
    } catch (error) {
      captureException(error as SentryError, { ctx })
    }
  }

  const userExperimentVariants =
    extractExperimentVariantsFromPath(ctx) ||
    extractExperimentVariantsFromRequest(ctx)

  const userAgent =
    (ctx.req ? ctx.req.headers['user-agent'] : navigator.userAgent) || ''

  const isMobileUserAgent = !!userAgent?.match(
    /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
  )

  return {
    authToken,
    user,
    userAgent,
    isMobileUserAgent,
    customIp,
    userExperimentVariants,
  }
}

export default withApolloClient<VitrineInitialProps>(VitrinePageApp)
