import React, { FC } from 'react'
import { theme, FontSizeProps } from '../../../../theme'

export const Ticket: FC<{
  size?: FontSizeProps
}> = ({ size }) => {
  return (
    <svg
      width={theme.fontSizes[size!]}
      height={theme.fontSizes[size!]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="3"
        y="3"
        width="18"
        height="19"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 3H21V21.0012H3V3Z"
          fill="white"
        />
      </mask>
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.7988 12.3848C18.7988 11.3476 18.1165 10.5067 16.8597 10.5067C15.7887 10.5067 14.9206 11.1983 14.9206 12.3848C14.9206 13.4784 15.7224 14.263 17.0665 14.263C17.5793 14.263 18.0145 14.0844 18.0145 14.0844L18.0865 13.2624C18.0865 13.2624 17.7997 13.4664 17.2165 13.4664C16.2144 13.4664 16.0884 12.6443 16.0884 12.6443H18.7988V12.3848ZM15.0203 14.1895C15.0203 14.1895 14.8639 13.8761 14.6753 13.3104C14.5373 12.8963 14.4409 12.4159 14.2733 12.2483C14.0573 12.0323 13.7093 12.0683 13.7093 12.0683L14.8853 10.5802H13.7183L12.7192 12.0075H12.6022V9.31412H11.5521V14.1895H12.6022V12.6984C12.6022 12.6984 12.6532 12.6984 13.0432 12.6984C13.5382 12.6984 13.4963 13.1124 13.6568 13.5708C13.7888 13.9478 13.8923 14.1895 13.8923 14.1895H15.0203ZM9.48576 12.3863C9.48576 11.7308 9.86597 11.3843 10.4969 11.3843C10.7697 11.3843 11.0541 11.5523 11.0541 11.5523L11.354 10.7495C11.0746 10.595 10.7217 10.5067 10.2894 10.5067C9.2337 10.5067 8.37795 11.1563 8.37795 12.3848C8.37795 13.5864 9.2337 14.263 10.2894 14.263C10.5746 14.263 10.8453 14.2016 11.0884 14.0915L11.1801 13.2204C11.1801 13.2204 10.9358 13.3884 10.4969 13.3884C9.93846 13.3884 9.48576 13.0274 9.48576 12.3863ZM6.96187 10.1182H8.04194V9.31412H6.96187V10.1182ZM6.96187 14.1895H8.04194V10.5802H6.96187V14.1895ZM20.7337 14.1804C19.7593 18.0981 16.2183 21.0012 11.9991 21.0012C7.02818 21.0012 2.99847 16.9715 2.99847 12.0006C2.99847 11.4186 3.05372 10.8495 3.15926 10.2982H4.06446V14.1895H5.2092V10.2982H6.59854L6.73354 9.31413H3.40629C4.54898 5.65542 7.9639 3 11.9991 3C16.97 3 20.9997 7.02971 20.9997 12.0006C20.9997 12.7281 20.9133 13.4353 20.7503 14.1127C20.237 13.4514 20.1023 12.7127 20.1023 12.2543C20.1023 11.7803 20.1023 11.4563 20.1023 11.4563H20.6844L20.8308 10.6522H20.1023V9.35013L19.0403 9.80017C19.0403 9.80017 19.0403 10.9642 19.0403 12.2243C19.0403 13.4844 19.6523 14.1804 19.6523 14.1804H20.7337ZM17.6725 11.9423H16.0524C16.0524 11.9423 16.1902 11.2583 16.8625 11.2583C17.6245 11.2583 17.6725 11.9423 17.6725 11.9423Z"
          fill="#D3232A"
        />
      </g>
    </svg>
  )
}

Ticket.defaultProps = {
  size: 'x3',
}
