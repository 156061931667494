import { useState, useEffect, useRef, MutableRefObject } from 'react'

export const useOnScreen = (ref: MutableRefObject<Element | null>) => {
  const renderedRef = useRef(false)
  const [isVisible, setVisible] = useState(false)

  const observer =
    typeof IntersectionObserver !== 'undefined'
      ? new IntersectionObserver(([entry]) => {
          renderedRef.current = renderedRef.current || entry.isIntersecting
          setVisible(entry.isIntersecting)
        })
      : undefined

  useEffect(() => {
    if (!observer) return
    ref.current && observer.observe(ref.current)
    return () => {
      observer.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    isVisible,
    isRendered: renderedRef.current,
  }
}
