import { CookieSerializeOptions, serialize } from 'cookie'
import dayjs from 'dayjs'
import { useEffect } from 'react'

import { beholder } from '@lojinha/beholder'
import { useLojinhaContext } from '@lojinha/context'
import { Address } from '@lojinha/context/src/types'
import { makeAddressId } from '@lojinha/location/src/address/logic'
import {
  AddressKind,
  DeliveryAddress,
  useApolloClient,
  VitrineContextQuery,
} from '@lojinha/palantir'
import { getIsLivupper } from '../helpers/isLivupper'
import { useOnboardingEvent } from '../helpers/useOnboardingEvent'
import { getLocalBag, parseAddresses } from './logic'
import { VIEWER_CONTEXT } from './queries'

export const SetupVitrineContext = () => {
  const {
    bag,
    setBag,
    setAddress,
    setCenterId,
    setIsContextLoaded,
    setIsFirstBuy,
    setIsLivupper,
    setReferralRewardAmount,
    setAllAddresses,
    isAuthenticated,
    setUser,
  } = useLojinhaContext()
  useOnboardingEvent()

  const client = useApolloClient()

  const getContext = async () => {
    const { data } = await client.query<VitrineContextQuery>({
      query: VIEWER_CONTEXT,
    })

    const cartItems =
      bag?.cartItems.map(cartItem => ({
        quantity: cartItem.quantity,
        sku: cartItem.item.sku,
        price: cartItem.item.price,
      })) || []

    const { user } = data.viewer

    const isSecureToken = process.env.NEXT_PUBLIC_NODE_ENV === 'production'

    const cookieOptions: CookieSerializeOptions = {
      sameSite: 'lax',
      secure: isSecureToken,
      path: '/',
      expires: dayjs().add(180, 'day').toDate(),
      domain: window.location.hostname,
    }

    const lastUserAddress = data?.viewer?.user?.addresses
      ?.nodes[0] as DeliveryAddress

    const isFirstBuy = serialize(
      '_isFirstBuy',
      String(data?.viewer?.user?.isFirstBuy) ?? 'true',
      cookieOptions
    )

    document.cookie = isFirstBuy

    const refreshCookie = serialize(
      '_centerId',
      lastUserAddress?.nearestDistributionCenter?.id || '',
      cookieOptions
    )

    document.cookie = refreshCookie

    beholder.shot('setUser', {
      id: user.id,
      email: user.email,
      name: user.name,
      phoneNumber: user.phoneNumber,
      city: lastUserAddress?.city,
      state: lastUserAddress?.state,
      country: lastUserAddress?.country,
      zipCode: lastUserAddress?.zipCode,
      cartItems,
    })

    return {
      userId: data?.viewer?.user?.id,
      userName: data?.viewer?.user?.name,
      userEmail: data?.viewer?.user?.email,
      userPhone: data?.viewer?.user?.phoneNumber,
      userCity: lastUserAddress?.city,
      userState: lastUserAddress?.state,
      userCountry: lastUserAddress?.country,
      userZipCode: lastUserAddress?.zipCode,
      isFirstBuy: data?.viewer?.user?.isFirstBuy ?? true,
      referralRewardAmount: data?.viewer?.user?.referralRewardAmount as number,
      allUserAddresses: data?.viewer?.user?.addresses?.nodes,
      lastUserAddress: data?.viewer?.user?.addresses?.nodes[0],
    }
  }

  const setupInitialContext = async () => {
    const localBag = getLocalBag()

    if (localBag) {
      setBag(localBag)
    }

    const {
      userId,
      userEmail,
      userName,
      userPhone,
      userCity,
      userCountry,
      userState,
      userZipCode,
      referralRewardAmount,
      allUserAddresses,
      lastUserAddress,
      isFirstBuy,
    } = await getContext()

    setReferralRewardAmount(referralRewardAmount)
    setUser(
      userId,
      userName || '',
      userEmail,
      userPhone,
      userCity,
      userCountry,
      userState,
      userZipCode
    )

    if (allUserAddresses) {
      const allAddresses = parseAddresses(allUserAddresses)

      setAllAddresses(allAddresses)
    }

    if (lastUserAddress?.__typename === 'DeliveryAddress') {
      const deliveryAddress = {
        ...lastUserAddress,
        id: makeAddressId(lastUserAddress),
      } as Address

      setAddress({
        ...deliveryAddress,
        kind: AddressKind.Delivery,
      })

      const deliveryCenterId = lastUserAddress?.nearestDistributionCenter?.id
      deliveryCenterId && setCenterId(deliveryCenterId)
    }

    if (userEmail) {
      const isLivupper = getIsLivupper(userEmail)
      setIsLivupper(isLivupper)
    }

    setIsFirstBuy(isFirstBuy)
    setIsContextLoaded(true)
  }

  useEffect(() => {
    if (isAuthenticated) setupInitialContext()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  return null
}

export default SetupVitrineContext
