import { generateAddressLabel } from '../helpers/generateAddressLabel'
import { beholder } from './beholder'
import { getFullTrackerName } from './snowplow/logic'

export const snowplowTrackerScript = `
  ;(function(p, l, o, w, i, n, g) {
      if (!p[i]) {
          p.GlobalSnowplowNamespace = p.GlobalSnowplowNamespace || [];
          p.GlobalSnowplowNamespace.push(i);
          p[i] = function() {
              (p[i].q = p[i].q || []).push(arguments)
          };
          p[i].q = p[i].q || [];
          n = l.createElement(o);
          g = l.getElementsByTagName(o)[0];
          n.async = 1;
          n.src = w;
          g.parentNode.insertBefore(n, g)
      }
  }(window, document, "script", "//storage.googleapis.com/snowplow-files/js_2_10_2.js", "snowplow"));
`

const convertToSnowplowCurrency = (value: number) => {
  return (value / 100).toFixed(2)
}

const snowplowStructEvent = ({
  category,
  action,
  label,
  property,
  value,
  experimentGroup,
}: {
  category: string
  action: string
  label?: string | null
  property?: string | string[] | null
  value?: number | null
  experimentGroup?: string | null
}) => {
  if (!experimentGroup) {
    window.snowplow(
      'trackStructEvent',
      category,
      action,
      label,
      property,
      value
    )
    return
  }
  const customContext = {
    schema: 'iglu:br.com.livup/experiments/jsonschema/1-0-0',
    data: {
      experiment_group: experimentGroup,
    },
  }

  window.snowplow(
    'trackStructEvent',
    category,
    action,
    label,
    property,
    value,
    customContext
  )
}

const snowplowProductLifecycle = (data: {
  product_id: string
  product_name: string
  product_action:
    | 'add_to_cart'
    | 'remove_from_cart'
    | 'impression'
    | 'product_detail_view'
  product_price?: number
  position_in_list?: number
  list?: string
  sorting_version?: string
  label?: string
  property?: string
  value?: number
  experiment_id?: string
  variant_id?: string
  experiment_group?: string
}) => {
  const { experiment_group, ...selfDescribingEventData } = data
  const selfDescribingJson = {
    schema: 'iglu:br.com.livup/ecommerce_product_lifecycle/jsonschema/4-0-0',
    data: selfDescribingEventData,
  }

  if (!experiment_group) {
    window.snowplow('trackSelfDescribingEvent', selfDescribingJson)
    return
  }
  const customContext = [
    {
      schema: 'iglu:br.com.livup/experiments/jsonschema/1-0-0',
      data: { experiment_group: experiment_group },
    },
  ]

  window.snowplow('trackSelfDescribingEvent', selfDescribingJson, customContext)
}

// Beholder functions to Landing Page
const snowplowTrackerLandingPage = () => {
  beholder.watch('heroCaption', ({ captionIndex, captionName, action }) => {
    snowplowStructEvent({
      category: 'landing_page',
      action: action,
      label: 'hero',
      property: captionName,
      value: captionIndex,
    })
  })
}

// Beholder functions to Checkout
const snowplowTrackerCheckout = () => {
  const trackerName = getFullTrackerName(
    process.env.NEXT_PUBLIC_SNOWPLOW_TRACKER_NAME,
    process.env.NEXT_PUBLIC_VERSION
  )

  // Initial setup
  window.snowplow(
    'newTracker',
    trackerName,
    process.env.NEXT_PUBLIC_SNOWPLOW_TRACKER_URL,
    {
      forceSecureTracker: true,
      appId: 'web',
      contexts: {
        webPage: true,
        performanceTiming: true,
      },
    }
  )
  window.snowplow('enableActivityTracking', 30, 10)

  // Beholder functions
  beholder.watch('trackPageView', ({ experimentGroup }) => {
    if (!experimentGroup) {
      window.snowplow('trackPageView', null)
      return
    }

    const customContext = [
      {
        schema: 'iglu:br.com.livup/experiments/jsonschema/1-0-0',
        data: { experiment_group: experimentGroup },
      },
    ]

    window.snowplow('trackPageView', null, customContext)
  })

  beholder.watch('setUser', user => {
    window.snowplow('setUserId', user.id)
  })

  beholder.watch('paymentMethodSelected', payload => {
    snowplowStructEvent({
      category: 'checkout',
      action: 'selected_payment_method',
      label: payload.method,
      property: payload.brand,
      experimentGroup: payload.experimentGroup,
    })
  })

  beholder.watch('couponFilled', payload => {
    snowplowStructEvent({
      category: payload.category,
      action: 'coupon_filled',
      label: payload.status,
      property: payload.nameOfCoupon,
    })

    if (payload.status === 'rejected') {
      snowplowStructEvent({
        category: payload.category,
        action: 'coupon_rejected',
        label: payload.rejectedReason,
        property: payload.nameOfCoupon,
      })
    }
  })

  beholder.watch('finished', payload => {
    snowplowStructEvent({
      category: 'checkout',
      action: 'step',
      label: 'order_placed',
      experimentGroup: payload.experimentGroup,
    })
    snowplowStructEvent({
      category: 'checkout',
      action: 'concluded',
      label: payload.deliveryPeriodId,
      experimentGroup: payload.experimentGroup,
    })

    const customContext = {
      schema: 'iglu:br.com.livup/experiments/jsonschema/1-0-0',
      data: {
        experiment_group: payload.experimentGroup || '',
      },
    }

    const addTransParams = [
      payload.orderNumber, // order ID - required
      'ecommerce', // affiliation or store name
      convertToSnowplowCurrency(payload.total), // total - required
      '0.00', // tax
      convertToSnowplowCurrency(payload.deliveryTax), // shipping
      null, // city
      null, // state or province
      null, // country
      'BRL', // currency
    ]

    if (!payload.experimentGroup) {
      window.snowplow('addTrans', ...addTransParams)
    } else {
      window.snowplow('addTrans', ...addTransParams, customContext)
    }

    payload.items.forEach(item => {
      const addItemParams = [
        payload.orderNumber, // order ID - required
        item.sku, // SKU/code - required
        item.name, // product name
        item.kind, // category or variation
        convertToSnowplowCurrency(item.price), // unit price - required
        item.quantity, // quantity - required
        'BRL', // currency
      ]

      if (!payload.experimentGroup) {
        window.snowplow('addItem', ...addItemParams)
        return
      }
      window.snowplow('addItem', ...addItemParams, customContext)
    })

    window.snowplow('trackTrans')
  })

  beholder.watch('addToCart', payload => {
    const {
      action,
      displayName,
      experimentGroup,
      item,
      isFirstBuy,
      list,
      positionInList,
      user,
    } = payload

    const listFormated = !displayName
      ? list
      : `${list}#${displayName?.toLocaleLowerCase().replace(/\s/g, '_')}`

    const userTypeLabel = isFirstBuy ? 'FIRST_BUY' : 'REBUY'

    snowplowProductLifecycle({
      experiment_group: experimentGroup,
      list: listFormated,
      position_in_list: positionInList,
      product_action: action === 'add' ? 'add_to_cart' : 'remove_from_cart',
      product_id: item.id,
      product_name: item.name,
      product_price: item.price,
      label: !user.email ? 'DESLOGADO' : userTypeLabel,
    })
  })

  beholder.watch('itemView', payload => {
    snowplowProductLifecycle({
      product_id: payload.id,
      product_action: 'impression',
      product_name: payload.name,
      list: payload.listName,
      position_in_list: payload.positionInList,
      product_price: payload.price,
      label: payload.isAvailable ? 'available' : 'not available',
    })
  })

  beholder.watch('productDetailView', payload => {
    snowplowProductLifecycle({
      product_id: payload.id,
      product_action: 'product_detail_view',
      product_name: payload.name,
      list: payload.listName,
      position_in_list: payload.positionInList,
      product_price: payload.price,
      label: payload.isAvailable ? 'available' : 'not available',
    })
  })
}

// Beholder functions
export const snowplowTrackerSetup = () => {
  snowplowTrackerLandingPage()
  snowplowTrackerCheckout()

  // Use to send Structured Events, search on notion 'Como criar eventos estruturados'
  // Events must be documented in the event dictionary, search on notion 'Events Web - Ecommerce'
  beholder.watch(
    'structuredEvent',
    ({ category, action, label, property, value, experimentGroup }) => {
      snowplowStructEvent({
        category,
        action,
        label,
        property,
        value,
        experimentGroup,
      })
    }
  )

  beholder.watch('localizationEmailLead', payload => {
    snowplowStructEvent({
      category: 'localization-email-lead',
      action: 'permission-response',
      label: payload.email,
      property: generateAddressLabel(payload.address),
    })
  })
}
