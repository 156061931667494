import React, { FC } from 'react'
import { theme, FontSizeProps } from '../../../../theme'

export const Elo: FC<{
  size?: FontSizeProps
}> = ({ size }) => {
  return (
    <svg
      width={theme.fontSizes[size!]}
      height={theme.fontSizes[size!]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.599 7V14.8793L13.8185 15.3759L13.1256 17L11.7793 16.437C11.4774 16.3056 11.2716 16.1045 11.1159 15.8774C10.9672 15.6459 10.8557 15.3284 10.8557 14.9009V7H12.599ZM8.03611 14.1896L8.03383 14.1917L7.95686 14.1395C7.73506 14.5012 7.38895 14.794 6.95202 14.9843C6.1211 15.3473 5.35103 15.254 4.79802 14.7667L4.74717 14.8444L4.74641 14.8432C4.74632 14.8431 4.74623 14.8431 4.74614 14.843C4.74598 14.8429 4.7458 14.8428 4.7456 14.8428L3.80252 16.26C4.03661 16.4235 4.28799 16.5619 4.55203 16.6726C5.59369 17.1072 6.65973 17.0872 7.70982 16.6285C8.46937 16.2979 9.06533 15.7936 9.47264 15.1546L8.03611 14.1896ZM5.09188 10.6929C4.21429 11.0718 3.76187 11.8986 3.85494 12.8682L7.57252 11.261C6.93325 10.5065 6.10128 10.2564 5.09188 10.6929ZM2.62366 15.0102C2.53492 14.8658 2.4144 14.6348 2.34165 14.4649C1.91276 13.4648 1.8922 12.4297 2.25448 11.436C2.65273 10.3464 3.41344 9.51207 4.3963 9.08733C5.6321 8.55312 6.99884 8.65844 8.18323 9.36434C8.93577 9.79795 9.46896 10.4673 9.87399 11.4139C9.90488 11.4863 9.93345 11.5616 9.96091 11.634L9.96091 11.634L9.96092 11.634L9.96092 11.634C9.97943 11.6828 9.99744 11.7302 10.0153 11.7745L2.62366 15.0102ZM16.5206 11.1906C16.0539 11.5995 15.7591 12.2012 15.7582 12.8721C15.7577 13.5416 16.0509 14.1415 16.5152 14.5521L15.2656 15.9549C14.4178 15.1995 13.8841 14.0973 13.8855 12.8703C13.8864 11.6437 14.421 10.5428 15.2694 9.78923L16.5206 11.1906ZM17.9815 15.1084C17.7351 15.1077 17.4979 15.0669 17.2766 14.9928L16.6789 16.7774C17.0873 16.9152 17.5247 16.9901 17.9796 16.9908C19.9607 16.9927 21.615 15.5807 21.9981 13.7037L20.1614 13.3269C19.9526 14.3447 19.0558 15.1093 17.9815 15.1084ZM16.6875 8.96739C17.0955 8.83097 17.5327 8.75677 17.9868 8.75728C19.9695 8.75867 21.6222 10.1759 22 12.0555L20.1632 12.4297C19.9593 11.4094 19.0619 10.6402 17.9848 10.6392C17.7388 10.6392 17.5016 10.6799 17.2796 10.7535L16.6875 8.96739Z"
        fill="#1A1919"
      />
    </svg>
  )
}

Elo.defaultProps = {
  size: 'x3',
}
