import React, { FC } from 'react'
import { theme, FontSizeProps, ColorProps } from '../../../theme'

export const User: FC<{
  color?: ColorProps
  size?: FontSizeProps
}> = ({ color, size }) => {
  return (
    <svg
      width={theme.fontSizes[size!]}
      height={theme.fontSizes[size!]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 7C9 5.34315 10.3431 4 12 4C13.6569 4 15 5.34315 15 7C15 8.65685 13.6569 10 12 10C10.3431 10 9 8.65685 9 7ZM12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2ZM19 18C19 16.8954 18.1046 16 17 16H7C5.89543 16 5 16.8954 5 18V19V20V22C3.89543 22 3 21.1046 3 20V18C3 15.7909 4.79086 14 7 14H17C19.2091 14 21 15.7909 21 18V20C21 21.1046 20.1046 22 19 22V20V19V18Z"
        fill={theme.colors[color!]}
      />
    </svg>
  )
}

User.defaultProps = {
  color: 'black',
  size: 'x2',
}
