import { ExperimentItem, Variants_Enum } from './types'

export const experiments: readonly ExperimentItem[] = [
  {
    id: 'sku_pricing',
    description: 'Teste A/B de precificação de SKUs',
    setInitialValueInSSR: false,
    variants: [
      {
        id: 'control',
        value: Variants_Enum.A,
        weight: 100,
      },
      {
        id: 'variant',
        value: Variants_Enum.B,
        weight: 0,
      },
    ],
  },
  {
    id: 'pix_cashback',
    description: 'Teste A/B de cashback para pagamentos via PIX',
    setInitialValueInSSR: false,
    variants: [
      {
        id: 'control',
        value: Variants_Enum.A,
        weight: 90,
      },
      {
        id: 'variant',
        value: Variants_Enum.B,
        weight: 10,
      },
    ],
  },
]
