import { parse } from 'cookie'
import { NextPageContext } from 'next'

export function getValueFromCookie(key: string, ctx?: NextPageContext) {
  const stringfiedCookies = process.browser
    ? document?.cookie
    : ctx?.req?.headers.cookie || ''

  return parse(stringfiedCookies)[key]
}
