import { Address } from '@lojinha/context/src/types'

export const singleAddress = (address: Address) => {
  const { complement, number, neighborhood, city, state, street } = address

  const addressComplement = complement ? `, ${complement}` : ''
  const addressNumber = number ? `, ${number}` : ''

  return `${street}${addressNumber}${addressComplement} - ${neighborhood}, ${city} - ${state}`
}
