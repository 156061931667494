import React, { FC } from 'react'
import { theme, FontSizeProps } from '../../../../theme'

export const Pluxee: FC<{
  size?: FontSizeProps
}> = ({ size }) => {
  return (
    <svg
      viewBox="0 -358 1000 1000"
      width={theme.fontSizes[size!]}
      height={theme.fontSizes[size!]}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="cls-1"
        d="M913.8752228,97.0371349c22.7859903,0,31.9836771,15.859299,31.9836771,26.759395h-63.9674174c0-11.0295385,9.2981185-26.759395,31.9837403-26.759395m26.8349874,75.7031539c-4.2582036,8.273971-14.008802,13.8720989-26.5989192,13.8720989-23.7791203,0-33.1797562-16.958004-33.4344698-32.0623902h118.1250771c.3303691-2.1270002,1.1981017-8.1662708,1.1981017-12.8769385,0-53.3437772-36.7015659-83.4614249-86.3080698-83.4614249-52.2916776,0-88.8617784,30.1176476-88.8617784,83.4614249,0,53.7383146,36.5690264,83.4624519,89.1703421,83.4624519,51.7221432,0,76.0221305-28.4845973,82.5108156-52.3952221h-55.8010992Z"
        fill="#1a1337"
      />
      <path
        className="cls-1"
        d="M90.8365352,177.2021289c-21.2130394,0-36.2760031-15.2058766-36.2760031-35.3947606,0-19.2475737,14.1465085-35.3937257,36.2760031-35.3937257,22.1336345,0,36.2718632,16.2403739,36.2718632,35.3937257,0,19.4546864-14.330844,35.3947606-36.2718632,35.3947606m15.8727616-117.3836505c-23.2737699,0-43.2400126,10.2673889-53.5374235,22.3345374l-.0341738-19.1875296H0v220.3826069H54.7790335v-78.9050548c12.8489745,13.8337892,30.7441489,19.0881091,48.8381405,19.0881091,54.2664422,0,79.4383494-41.0146308,79.4383494-81.8584045,0-50.4049013-32.3326757-81.8542646-76.3462266-81.8542646"
        fill="#1a1337"
      />
      <rect
        className="cls-1"
        x="201.1002234"
        width="55.0027176"
        height="220.3815561"
        fill="#1a1337"
      />
      <path
        className="cls-1"
        d="M388.8212891,62.9648438v81.8573608c0,12.4078369-1.7076416,18.43573-6.7900391,24.4501343-4.9115601,5.8114624-12.6160278,8.5515137-21.1581421,8.5515137-8.5360107,0-16.2539062-2.7400513-21.1675415-8.5515137-5.0823975-6.0144043-6.7807007-12.0422974-6.7807007-24.4501343V62.9648438h-54.7966309l.0072632,86.7213135c0,24.3807373,3.7279663,40.2928467,15.430603,53.8170166,12.3788452,14.303894,31.2857056,19.9910889,49.7970581,19.9910889,21.6834106,0,36.7026978-8.1478271,47.0830688-20.1439819l.0350342,17.0311279h53.1366577V62.9648438h-54.7966309Z"
        fill="#1a1337"
      />
      <polygon
        className="cls-1"
        points="622.2633661 62.9652808 584.8192123 62.9652808 543.5539762 104.9688701 502.2887718 62.9652808 464.8446812 62.9652808 464.8446812 100.4093872 506.8493133 141.6735804 464.845724 182.9377815 464.845724 220.3818801 502.2898146 220.3818801 543.5539762 178.3793336 584.8182011 220.3818801 622.2622917 220.3818801 622.2622917 182.9377815 580.2597768 141.6735804 622.2633661 100.4093872 622.2633661 62.9652808"
        fill="#1a1337"
      />
      <path
        className="cls-1"
        d="M723.4617491,97.0371349c22.7870016,0,31.9846884,15.859299,31.9846884,26.759395h-63.9684287c0-11.0295385,9.2981185-26.759395,31.9837403-26.759395m26.8349874,75.7031539c-4.2582036,8.273971-14.008802,13.8720989-26.5989192,13.8720989-23.7791203,0-33.1797562-16.958004-33.4334585-32.0623902h118.1261515c.3313804-2.1270002,1.1981017-8.1662708,1.1981017-12.8769385,0-53.3437772-36.7016291-83.4614249-86.308133-83.4614249-52.2916144,0-88.8617152,30.1176476-88.8617152,83.4614249,0,53.7383146,36.5690264,83.4624519,89.1692676,83.4624519,51.7231544,0,76.0231418-28.4845973,82.5118901-52.3952221h-55.8031849Z"
        fill="#1a1337"
      />
    </svg>
  )
}

Pluxee.defaultProps = {
  size: 'x3',
}
