import { parse as parseCookie } from 'cookie'

import {
  Variants_Enum,
  experiments,
  getExperimentCookieName,
} from '@lojinha/experiment'
import { stringToValidEnumValue } from '@lojinha/experiment/src/logic'
import { AppWithApolloCtx } from '@lojinha/palantir/src/apolloTypes'

const REGEX_COOKIE_SNOWPLOW = /^_sp_id.[0-9a-f]{4}/

function getParsedSessionIdCookie(parseCookies: string[]) {
  const sessionIdCookie = parseCookies.find(cookie => {
    return cookie.match(REGEX_COOKIE_SNOWPLOW)
  })

  return sessionIdCookie && sessionIdCookie.split('=')[1].split('.')[0]
}

function cookiesToUserExperimentVariants(
  cookies: Record<string, string>
): Record<string, Variants_Enum> {
  return experiments.reduce<Record<string, Variants_Enum>>((acc, cur) => {
    const cookieName = getExperimentCookieName(cur)
    if (!cookieName) {
      return acc
    }

    const value = cookies[cookieName]?.toUpperCase().substring(0, 1)
    const enumValue = stringToValidEnumValue(value)
    if (enumValue) {
      acc[cur.id] = enumValue
    }

    return acc
  }, {})
}

function getCookiesFromResponse(ctx: AppWithApolloCtx) {
  const newCookiesAsString = ctx.res?.getHeader('set-cookie')
  if (!(newCookiesAsString instanceof Array)) {
    return {}
  }
  return parseCookie(
    newCookiesAsString.map(cookie => cookie.split('; ')[0]).join('; ')
  )
}

function getCookiesFromRequest(ctx: AppWithApolloCtx) {
  return parseCookie(ctx.req?.headers.cookie || '')
}

export function getCookieSessionId() {
  if (typeof window === 'undefined') {
    return
  }

  const cookies = window.document.cookie
  const parseCookies = cookies.split('; ')

  return getParsedSessionIdCookie(parseCookies)
}

export function extractCookiesFromDocument(): Record<string, string> {
  if (typeof window === 'undefined') {
    return {}
  }
  return parseCookie(window.document.cookie)
}

export function extractExperimentVariantsFromRequest(
  ctx: AppWithApolloCtx
): Record<string, Variants_Enum> {
  const cookiesFromResponse = getCookiesFromResponse(ctx)
  const cookiesFromRequest = getCookiesFromRequest(ctx)
  const cookiesFromDocument = extractCookiesFromDocument()
  return cookiesToUserExperimentVariants({
    ...cookiesFromRequest,
    ...cookiesFromResponse,
    ...cookiesFromDocument,
  })
}

export function extractExperimentVariantsFromPath(
  ctx: AppWithApolloCtx
): Record<string, Variants_Enum> | null {
  const staticHomeWithExperiments = ctx.asPath?.match(
    /^\/dc\/[a-z0-f]{24}\/([a-z]+)\/.+/
  )

  if (!staticHomeWithExperiments) {
    return null
  }

  return staticHomeWithExperiments[1]
    .split('')
    .reduce(
      (acc: Record<string, Variants_Enum>, cur: string, index: number) => {
        const homeExperiments = experiments.filter(e => e.setInitialValueInSSR)
        const id = homeExperiments[index]?.id
        const enumValue = stringToValidEnumValue(cur)
        if (id && enumValue) {
          acc[id] = enumValue
        }
        return acc
      },
      {}
    )
}
