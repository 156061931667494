import { beholder } from './beholder'

export const tiktokTrackerScript = `
  !function (w, d, t) {
    w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
  )ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};

    ttq.load("${process.env.NEXT_PUBLIC_TIKTOK_ID}");
    ttq.page();
  }(window, document, 'ttq');
`

const convertToTiktokCurrency = (value: number) => {
  return Number((value / 100).toFixed(2))
}

const convertToTiktokProducts = ({
  items,
  displayCategory,
}: {
  items: Array<{
    sku?: string
    name?: string
    price: number
    quantity?: number
    url?: string
    imageUrl?: string
  }>
  displayCategory?: string
}) =>
  items.map(({ sku, quantity, price, name }) => ({
    content_id: sku,
    content_name: name,
    content_category: displayCategory,
    price: convertToTiktokCurrency(price),
    quantity,
  }))

const converToTiktokPhoneFormat = (phoneNumber: string) => {
  const purePhoneNumber = phoneNumber.replace(/[^\d]/g, '')
  return `+55${purePhoneNumber}`
}

const identifyTrackedUser = ({
  email,
  phoneNumber,
}: {
  email?: string
  phoneNumber?: string | null
}) => {
  if (!email && !phoneNumber) {
    return
  }

  return window.ttq.instance(process.env.NEXT_PUBLIC_TIKTOK_ID).identify({
    email: email || '',
    phone_number: phoneNumber ? converToTiktokPhoneFormat(phoneNumber) : '',
  })
}

export const tiktokTrackerSetup = () => {
  beholder.watch(
    'addToCart',
    ({ item, action, user: { email, phoneNumber } }) => {
      if (action != 'add') {
        return
      }

      email &&
        identifyTrackedUser({
          email,
          phoneNumber,
        })
      window.ttq
        .instance(process.env.NEXT_PUBLIC_TIKTOK_ID)
        .track('AddToCart', {
          contents: [
            {
              content_id: item.id,
              content_name: item.name,
              content_category: item.category,
              price: convertToTiktokCurrency(item.price),
              quantity: 1,
            },
          ],
          currency: 'BRL',
          content_type: 'product',
          value: convertToTiktokCurrency(item.price),
        })
    }
  )

  beholder.watch('checkoutInitiated', ({ cartItems, cartSubTotal, user }) => {
    const tiktokCartItems = convertToTiktokProducts({ items: cartItems })

    identifyTrackedUser({
      email: user.email,
      phoneNumber: user.phoneNumber,
    })
    window.ttq
      .instance(process.env.NEXT_PUBLIC_TIKTOK_ID)
      .track('InitiateCheckout', {
        contents: tiktokCartItems,
        currency: 'BRL',
        content_type: 'product',
        value: cartSubTotal && convertToTiktokCurrency(cartSubTotal),
      })
  })

  beholder.watch('finished', ({ items, total, user }) => {
    const tiktokCartItems = convertToTiktokProducts({ items })

    identifyTrackedUser({ email: user?.email, phoneNumber: user?.phoneNumber })
    window.ttq
      .instance(process.env.NEXT_PUBLIC_TIKTOK_ID)
      .track('CompletePayment', {
        contents: tiktokCartItems,
        currency: 'BRL',
        content_type: 'product',
        value: convertToTiktokCurrency(total),
      })
  })

  beholder.watch('productDetailView', ({ id, name, price, user }) => {
    identifyTrackedUser({
      email: user.email,
      phoneNumber: user.phoneNumber,
    })
    window.ttq
      .instance(process.env.NEXT_PUBLIC_TIKTOK_ID)
      .track('ViewContent', {
        contents: [
          {
            content_id: id,
            content_name: name,
            quantity: 1,
            price: convertToTiktokCurrency(price),
          },
        ],
        currency: 'BRL',
        content_type: 'product',
        value: convertToTiktokCurrency(price),
        description: 'View product details page',
      })
  })
}
