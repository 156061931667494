import React, { FC } from 'react'
import { theme, FontSizeProps } from '../../../../theme'

export const Ben: FC<{
  size?: FontSizeProps
}> = ({ size }) => {
  return (
    <svg
      width={theme.fontSizes[size!]}
      height={theme.fontSizes[size!]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="3"
        y="3"
        width="18"
        height="18"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 3H21V20.9998H3V3Z"
          fill="white"
        />
      </mask>
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.3793 9.53668C14.8761 9.52813 14.4718 9.12381 14.4633 8.62212C14.4026 5.50712 11.86 3 8.73245 3C5.56663 3 3 5.56724 3 8.73282V20.0887C3 20.5913 3.40786 20.9998 3.91187 20.9998H15.2669C18.4329 20.9998 21.0001 18.4348 21.0001 15.2682C21.0001 12.1398 18.4929 9.59594 15.3793 9.53668Z"
          fill="#070803"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6026 14.8646H12.6142V14.7058C12.6142 14.1267 12.2793 13.8376 11.6 13.8376C10.9384 13.8376 10.6026 14.1267 10.6026 14.7058V14.8646ZM15.3135 17.148C15.3135 17.4646 15.2351 17.5439 14.9177 17.5439H14.8053C14.4872 17.5439 14.4088 17.4646 14.4088 17.148V14.6976C14.4088 13.565 15.0497 12.9948 16.3235 12.9948C17.658 12.9948 18.2291 13.565 18.2291 14.6976V17.148C18.2291 17.4646 18.1506 17.5439 17.8347 17.5439H17.7196C17.4036 17.5439 17.3252 17.4646 17.3252 17.148V14.6976C17.3252 14.1002 17.1046 13.8374 16.3235 13.8374C15.5425 13.8374 15.3135 14.1002 15.3135 14.6976V17.148ZM7.90064 15.6461C7.90064 16.384 7.67216 16.6913 6.80207 16.6913C5.9326 16.6913 5.68737 16.3753 5.68737 15.6461V14.8911C5.68737 14.1531 5.9326 13.8364 6.78436 13.8364H6.80207C7.65517 13.8364 7.90064 14.1531 7.90064 14.8911V15.6461ZM6.79865 12.9947C6.73633 12.9947 6.8601 12.9947 6.79865 12.9947C6.43233 12.9947 5.94322 13.052 5.68737 13.1665V11.6941C5.68737 11.3773 5.60831 11.2996 5.29234 11.2996H5.18555C4.87031 11.2996 4.79114 11.3773 4.79114 11.6941V15.6629C4.79114 16.9203 5.53757 17.5439 6.79364 17.5439C8.13719 17.5439 8.8053 16.9365 8.8053 15.6629V14.9087C8.8053 13.6358 8.02001 12.9947 6.79865 12.9947ZM13.495 15.1977C13.4603 15.5242 13.3726 15.6019 13.0475 15.6019H10.6055V15.7954C10.6055 16.4366 10.9751 16.6904 11.862 16.6904C12.2227 16.6904 12.5994 16.6472 13.003 16.5504C13.2843 16.4797 13.3634 16.5504 13.3634 16.8579V16.9733C13.3634 17.263 13.3104 17.3236 13.003 17.4029C12.653 17.4989 12.2743 17.5443 11.862 17.5443C10.3863 17.5443 9.70067 16.9894 9.70067 15.6641V14.8993C9.70067 13.6255 10.3509 12.9944 11.6509 12.9944C12.8722 12.9944 13.5132 13.6172 13.5132 14.811C13.5132 14.9437 13.5039 15.0667 13.495 15.1977Z"
        fill="#FEFEFE"
      />
    </svg>
  )
}

Ben.defaultProps = {
  size: 'x3',
}
