import React, { FC } from 'react'
import { theme, FontSizeProps, ColorProps } from '../../../theme'

export const CreditCard: FC<{
  color?: ColorProps
  size?: FontSizeProps
}> = ({ color, size }) => {
  return (
    <svg
      width={theme.fontSizes[size!]}
      height={theme.fontSizes[size!]}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="CartaoDeCredito/24">
        <path
          id="Fill 1"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.78 27.024H5.22C4.8225 27.024 4.5 26.7015 4.5 26.304V17.28C4.5 16.8825 4.8225 16.56 5.22 16.56H30.78C31.1775 16.56 31.5 16.8825 31.5 17.28V26.304C31.5 26.7015 31.1775 27.024 30.78 27.024ZM5.22 9.06H30.78C31.1775 9.06 31.5 9.3825 31.5 9.78V12.84C31.5 13.2375 31.1775 13.56 30.78 13.56H5.22C4.8225 13.56 4.5 13.2375 4.5 12.84V9.78C4.5 9.3825 4.8225 9.06 5.22 9.06ZM34.5 13.56V7.44C34.5 6.645 33.855 6 33.06 6H2.94C2.145 6 1.5 6.645 1.5 7.44V13.56V18.06V28.68C1.5 29.475 2.145 30.12 2.94 30.12H33.06C33.855 30.12 34.5 29.475 34.5 28.68V18.06V13.56Z"
          fill={theme.colors[color!]}
        />
      </g>
    </svg>
  )
}

CreditCard.defaultProps = {
  color: 'black',
  size: 'x2',
}
