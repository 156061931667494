export type FbPixelUser = {
  id?: string | null
  email?: string | null
  name?: string | null
  phoneNumber?: string | null
  city?: string | null
  state?: string | null
  country?: string | null
  zipCode?: string | null
}

export function adaptUserToFbPixelUser(user?: FbPixelUser) {
  if (!user) {
    return {}
  }

  const phoneNumber = user.phoneNumber?.replace(/\D/g, '') || ''
  const shouldAddDDI = phoneNumber?.length >= 10 && phoneNumber?.length <= 11
  const names = (user.name || '').split(' ')

  return Object.fromEntries(
    Object.entries({
      em: user.email ?? '',
      fn: names[0] ?? '',
      ln: names.length > 1 ? names[names.length - 1] : '',
      ph: `${shouldAddDDI ? '55' : ''}${phoneNumber}`,
      external_id: user.id ?? '',
      ct: user.city ?? '',
      st: user.state ?? '',
      zp: user.zipCode ?? '',
      country: 'br',
    }).map(([key, value]) => [
      key,
      value
        .toLowerCase()
        .normalize('NFKD')
        .replace(/\p{Diacritic}/gu, ''),
    ])
  )
}
