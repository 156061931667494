import React, { FC } from 'react'
import { theme, FontSizeProps } from '../../../../theme'

export const Jcb: FC<{
  size?: FontSizeProps
}> = ({ size }) => {
  return (
    <svg
      width={theme.fontSizes[size!]}
      height={theme.fontSizes[size!]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M21.9333 16.283C21.9333 17.983 20.549 19.3674 18.8489 19.3674H2.06665V7.1511C2.06665 5.45102 3.451 4.06667 5.15108 4.06667H21.9333V16.283Z"
          fill="white"
        />
        <path
          d="M16.4688 13.1499H17.7438C17.7802 13.1499 17.8652 13.1378 17.9017 13.1378C18.1445 13.0892 18.351 12.8706 18.351 12.567C18.351 12.2756 18.1445 12.057 17.9017 11.9963C17.8652 11.9842 17.7924 11.9842 17.7438 11.9842H16.4688V13.1499Z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M17.5981 5.09884C16.3838 5.09884 15.388 6.08246 15.388 7.30894V9.60405H18.5089C18.5817 9.60405 18.6667 9.60405 18.7274 9.6162C19.4318 9.65263 19.9539 10.0169 19.9539 10.6484C19.9539 11.1463 19.6018 11.5713 18.946 11.6563V11.6806C19.6625 11.7292 20.2089 12.1299 20.2089 12.7492C20.2089 13.4171 19.6018 13.8543 18.8003 13.8543H15.3759V18.3473H18.6181C19.8325 18.3473 20.8282 17.3637 20.8282 16.1372V5.09884H17.5981Z"
          fill="url(#paint1_linear)"
        />
        <path
          d="M18.1931 10.7941C18.1931 10.5027 17.9867 10.3084 17.7438 10.2719C17.7195 10.2719 17.6588 10.2598 17.6224 10.2598H16.4688V11.3284H17.6224C17.6588 11.3284 17.7317 11.3284 17.7438 11.3163C17.9867 11.2798 18.1931 11.0855 18.1931 10.7941Z"
          fill="url(#paint2_linear)"
        />
        <path
          d="M5.3818 5.09884C4.16745 5.09884 3.17169 6.08246 3.17169 7.30894V12.7613C3.791 13.0649 4.43461 13.2592 5.07821 13.2592C5.84325 13.2592 6.25612 12.7978 6.25612 12.1663V9.59191H8.1505V12.1542C8.1505 13.1499 7.53118 13.9635 5.43037 13.9635C4.15531 13.9635 3.15955 13.6842 3.15955 13.6842V18.3352H6.40184C7.61619 18.3352 8.61195 17.3516 8.61195 16.1251V5.09884H5.3818Z"
          fill="url(#paint3_linear)"
        />
        <path
          d="M11.49 5.09884C10.2756 5.09884 9.27985 6.08246 9.27985 7.30894V10.1991C9.83844 9.72549 10.8099 9.4219 12.3764 9.49476C13.2143 9.53119 14.1129 9.76192 14.1129 9.76192V10.697C13.6636 10.4662 13.1293 10.2598 12.4371 10.2112C11.2471 10.1262 10.5306 10.7091 10.5306 11.7292C10.5306 12.7613 11.2471 13.3442 12.4371 13.2471C13.1293 13.1985 13.6636 12.9799 14.1129 12.7613V13.6964C14.1129 13.6964 13.2265 13.9271 12.3764 13.9635C10.8099 14.0364 9.83844 13.7328 9.27985 13.2592V18.3595H12.5221C13.7365 18.3595 14.7323 17.3759 14.7323 16.1494V5.09884H11.49Z"
          fill="url(#paint4_linear)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="15.386"
          y1="12.5685"
          x2="20.8429"
          y2="12.5685"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#007940" />
          <stop offset="0.2285" stopColor="#00873F" />
          <stop offset="0.7433" stopColor="#40A737" />
          <stop offset="1" stopColor="#5CB531" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="15.3858"
          y1="11.7178"
          x2="20.8432"
          y2="11.7178"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#007940" />
          <stop offset="0.2285" stopColor="#00873F" />
          <stop offset="0.7433" stopColor="#40A737" />
          <stop offset="1" stopColor="#5CB531" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="15.3858"
          y1="10.7926"
          x2="20.843"
          y2="10.7926"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#007940" />
          <stop offset="0.2285" stopColor="#00873F" />
          <stop offset="0.7433" stopColor="#40A737" />
          <stop offset="1" stopColor="#5CB531" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="3.16912"
          y1="11.7178"
          x2="8.71054"
          y2="11.7178"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1F286F" />
          <stop offset="0.4751" stopColor="#004E94" />
          <stop offset="0.8261" stopColor="#0066B1" />
          <stop offset="1" stopColor="#006FBC" />
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="9.24845"
          y1="11.7178"
          x2="14.6305"
          y2="11.7178"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6C2C2F" />
          <stop offset="0.1735" stopColor="#882730" />
          <stop offset="0.5731" stopColor="#BE1833" />
          <stop offset="0.8585" stopColor="#DC0436" />
          <stop offset="1" stopColor="#E60039" />
        </linearGradient>
        <clipPath id="clip0">
          <rect
            width="20"
            height="15.4341"
            fill="white"
            transform="translate(2 4)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

Jcb.defaultProps = {
  size: 'x3',
}
