import { ApolloLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { clearCookies, IS_SERVER } from '@lojinha/helpers'
import { captureException, SentryError } from '@lojinha/sentry'
import { discardAuthToken } from './authToken'

export const breadcrumbsLink = new ApolloLink((operation, forward) => {
  return forward ? forward(operation) : null
})

function handleUnauthorizedAccess(errorMessage: string) {
  const unauthorizedMessage =
    'Access denied! You need to be authorized to perform this action!'
  if (errorMessage === unauthorizedMessage) {
    discardAuthToken()
    clearCookies()
    localStorage.clear()
    window.location.href = '/identification'
  }
}

export const errorLink = onError(error => {
  const { graphQLErrors, networkError, operation } = error
  const { operationName } = operation
  const variables = JSON.stringify(
    redactVariables(operation.variables),
    null,
    2
  )

  if (graphQLErrors && graphQLErrors.length > 0) {
    graphQLErrors.forEach(({ message, ...graphQLError }) => {
      if (!IS_SERVER) {
        handleUnauthorizedAccess(message)
      }

      const err: SentryError = new Error(
        `[GraphQL error] - ${operationName}: ${message}`
      )

      err.level =
        message === 'Request failed with status code 400' ? 'warning' : 'error'

      err.errorInfo = {
        ...graphQLError,
        operationName,
        variables,
      }
      captureException(err)
      console.error(err)
    })
    return
  }

  if (networkError) {
    const err: SentryError = new Error(
      `[Network error] - ${operationName}: ${networkError}`
    )
    err.errorInfo = {
      operationName,
      variables,
      networkError,
    }
    captureException(err)
    console.error(err)
    return
  }

  const err: SentryError = new Error('[Unknown GraphQL error]')
  err.errorInfo = {
    operationName,
    variables,
  }
  captureException(err)
  console.error(err)
})

const redactkeys = [
  'input.password',
  'input.card.cvv',
  'input.card.expirationDate',
  'input.card.holderDocument',
  'input.card.holderName',
  'input.card.number',
]

type Variables = Record<string, unknown>

function redactVariables(
  variables: Variables,
  keyPath: string[] = []
): Variables {
  return Object.entries(variables).reduce((acc, [key, val]) => {
    const currentKeyPath = keyPath.concat(key)

    if (isObject(val)) {
      return {
        ...acc,
        [key]: redactVariables(val as Variables, currentKeyPath),
      }
    }

    const shouldRedact = redactkeys.includes(currentKeyPath.join('.'))

    return {
      ...acc,
      [key]: shouldRedact ? '***' : val,
    }
  }, {} as Variables)
}

function isObject(val: any) {
  return typeof val === 'object' && val !== null
}
