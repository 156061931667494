import React, { FC } from 'react'
import { theme, FontSizeProps } from '../../../../theme'

export const Visa: FC<{
  size?: FontSizeProps
}> = ({ size }) => {
  return (
    <svg
      width={theme.fontSizes[size!]}
      height={theme.fontSizes[size!]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6666 15.2128H9.04651L10.0599 9.11061H11.6799L10.6666 15.2128Z"
        fill="#0742A6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5398 9.25979C16.2202 9.13633 15.7134 9 15.0866 9C13.4867 9 12.36 9.83089 12.3531 11.0188C12.3398 11.8953 13.1598 12.3821 13.773 12.6743C14.3999 12.9729 14.6129 13.1678 14.6129 13.434C14.6066 13.8428 14.1064 14.0313 13.64 14.0313C12.9931 14.0313 12.6466 13.9341 12.1199 13.7067L11.9065 13.6092L11.6798 14.979C12.0598 15.1476 12.7599 15.2972 13.4867 15.3037C15.1867 15.3037 16.2934 14.4857 16.3065 13.2198C16.313 12.5251 15.88 11.9929 14.9466 11.5579C14.3799 11.2787 14.0329 11.0904 14.0329 10.8048C14.0395 10.545 14.3264 10.279 14.9661 10.279C15.4928 10.266 15.8798 10.3893 16.1729 10.5127L16.3194 10.5775L16.5398 9.25979Z"
        fill="#0742A6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4398 9.11061H20.693L22 15.2127H20.4999C20.4999 15.2127 20.3531 14.5116 20.3066 14.2974H18.2265L17.8864 15.2127H16.1865L18.593 9.61691C18.7597 9.22088 19.0533 9.11061 19.4398 9.11061ZM19.3399 11.3437C19.3399 11.3437 18.8265 12.7005 18.6931 13.051H20.0397C19.9731 12.7459 19.6663 11.2853 19.6663 11.2853L19.5531 10.7595C19.5054 10.8949 19.4365 11.081 19.39 11.2066C19.3584 11.2917 19.3372 11.3489 19.3399 11.3437Z"
        fill="#0742A6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.6933 9.11061L6.10665 13.2717L5.93326 12.4278C5.63993 11.454 4.71995 10.396 3.69331 9.86998L5.14663 15.2063H6.85988L9.40646 9.11061H7.6933V9.11061Z"
        fill="#0742A6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.63331 9.11061H2.02667L2 9.23391C4.03335 9.7403 5.37999 10.9609 5.93327 12.4281L5.36662 9.62356C5.27332 9.23382 4.98663 9.12347 4.63331 9.11061Z"
        fill="#0742A6"
      />
    </svg>
  )
}

Visa.defaultProps = {
  size: 'x3',
}
