import React, { FC } from 'react'
import { theme, FontSizeProps } from '../../../../theme'

export const Dinners: FC<{
  size?: FontSizeProps
}> = ({ size }) => {
  return (
    <svg
      width={theme.fontSizes[size!]}
      height={theme.fontSizes[size!]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5179 18.9999C17.3471 19.0182 20.8421 15.8794 20.8421 12.0608C20.8421 7.88498 17.3471 4.99861 13.5179 5H10.2225C6.34743 4.99861 3.1579 7.88585 3.1579 12.0608C3.1579 15.8802 6.34743 19.0183 10.2225 18.9999H13.5179Z"
        fill="#3377B9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.236 5.5833C6.69515 5.58441 3.82585 8.45245 3.82501 11.9922C3.82585 15.5313 6.69512 18.3991 10.236 18.4002C13.7778 18.3991 16.6477 15.5313 16.6482 11.9922C16.6476 8.45245 13.7778 5.58441 10.236 5.5833ZM6.17224 11.9922C6.17558 10.2625 7.25664 8.7875 8.78108 8.20137V15.7822C7.25664 15.1964 6.17555 13.7222 6.17224 11.9922ZM11.6905 15.7838V8.2011C13.2155 8.78582 14.2982 10.2616 14.301 11.9922C14.2982 13.7233 13.2155 15.198 11.6905 15.7838Z"
        fill="#F4F6F7"
      />
    </svg>
  )
}

Dinners.defaultProps = {
  size: 'x3',
}
