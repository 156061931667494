import { parse, serialize, CookieSerializeOptions } from 'cookie'
import { NextPageContext } from 'next'
import dayjs from 'dayjs'

export const getAuthToken = (ctx?: NextPageContext) => {
  const stringfiedCookies = process.browser
    ? document && document.cookie
    : (ctx && ctx.req && ctx.req.headers.cookie) || ''

  const { _jwt: jwtToken } = parse(stringfiedCookies)

  if (!jwtToken) return null

  return `Bearer ${jwtToken}`
}

export const setAuthToken = ({
  accessToken,
  refreshToken,
}: {
  accessToken: string
  refreshToken: string
}) => {
  const isSecureToken = process.env.NEXT_PUBLIC_NODE_ENV === 'production'

  const cookieOptions: CookieSerializeOptions = {
    sameSite: 'lax',
    secure: isSecureToken,
    path: '/',
    expires: dayjs().add(180, 'day').toDate(),
    domain: window.location.hostname,
  }

  const jwtCookie = serialize('_jwt', accessToken, cookieOptions)
  const refreshCookie = serialize('_refreshToken', refreshToken, cookieOptions)

  document.cookie = jwtCookie
  document.cookie = refreshCookie

  return `Bearer ${accessToken}`
}

export const discardAuthToken = () => {
  const isSecureToken = process.env.NEXT_PUBLIC_NODE_ENV === 'production'

  const cookieOptions: CookieSerializeOptions = {
    sameSite: 'lax',
    secure: isSecureToken,
    path: '/',
    expires: dayjs().add(-180, 'day').toDate(),
    domain: window.location.hostname,
  }

  const jwtCookie = serialize('_jwt', '', cookieOptions)
  const refreshCookie = serialize('_refreshToken', '', cookieOptions)

  document.cookie = jwtCookie
  document.cookie = refreshCookie
}
