import { captureException } from '@lojinha/sentry'
import { useEffect } from 'react'

import { getAddressFromUser } from '@lojinha/helpers'
import { beholder } from './beholder'
import { criteoTrackerScript, criteoTrackerSetup } from './criteo'
import { facebookPixelScript, facebookTrackerSetup } from './facebook'
import { getSiteControlTrackerSetup } from './getSiteControl'
import {
  googleAnalyticsScript,
  googleAnalyticsTrackerSetup,
} from './googleAnalytics'
import { setupInsiderUserObject } from './insider'
import { partnerTagManagerScript } from './partnerTagManager'
import { segmentTrackerScript, segmentTrackerSetup } from './segment'
import { snowplowTrackerScript, snowplowTrackerSetup } from './snowplow'
import { tagManagerScript, tagManagerTrackSetup } from './tagManager'
import { tiktokTrackerScript, tiktokTrackerSetup } from './tiktok'

const initializedTrackers: string[] = []

const setupTracker = (name: string, setupFunc: () => void) => {
  if (initializedTrackers.includes(name)) {
    return
  }
  initializedTrackers.push(name)
  try {
    setupFunc()
  } catch (error: any) {
    captureException(error)
  }
}

const beholderSetup = () => {
  setupTracker('tiktok', () => tiktokTrackerSetup())
  setupTracker('snowplow', () => snowplowTrackerSetup())
  setupTracker('segment', () => segmentTrackerSetup())
  setupTracker('criteo', () => criteoTrackerSetup())
  setupTracker('tagManager', () => tagManagerTrackSetup())
  setupTracker('getSiteControl', () => getSiteControlTrackerSetup())
  setupTracker('insider', () => setupInsiderUserObject())
  setupTracker('googleAnalytics', () => googleAnalyticsTrackerSetup())
  setupTracker('facebookPixel', () => facebookTrackerSetup())
}

const scripts = [
  {
    id: 'script-snowplow',
    innerHTML: snowplowTrackerScript,
  },
  {
    id: 'script-tagmanager',
    innerHTML: tagManagerScript,
  },
  {
    id: 'script-partner-tagmanager',
    innerHTML: partnerTagManagerScript,
  },
  {
    id: 'script-gtag',
    src: `https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}`,
  },
  {
    id: 'script-ganalytics',
    innerHTML: googleAnalyticsScript,
  },
  {
    id: 'script-tiktok',
    innerHTML: tiktokTrackerScript,
  },
  {
    id: 'script-segment',
    innerHTML: segmentTrackerScript,
  },
  {
    id: 'script-criteo-lib',
    src: '//static.criteo.net/js/ld/ld.js',
  },
  {
    id: 'script-criteo',
    innerHTML: criteoTrackerScript,
  },
  {
    id: 'script-get-site-control',
    src: '//l.getsitecontrol.com/l465197m.js',
  },
  {
    id: 'facebook-pixel',
    innerHTML: facebookPixelScript,
  },
]

export const Trackers = (props: Record<string, any>) => {
  const { experiment, user } = props
  const disableTrackers = () => {
    if (window.location.search.includes('disable-trackers=true')) {
      sessionStorage.setItem('disable-trackers', 'true')
    }

    if (sessionStorage.getItem('disable-trackers') === 'true') {
      return true
    }

    return false
  }

  useEffect(() => {
    if (disableTrackers()) return

    scripts.forEach(script => {
      if (document.getElementById(script.id)) {
        return
      }

      const el = document.createElement('script')
      el.type = 'text/javascript'
      el.id = script.id
      if (script.innerHTML) {
        el.innerHTML = script.innerHTML
      }
      if (script.src) {
        el.src = script.src
        el.async = true
      }
      document.getElementsByTagName('head')[0].appendChild(el)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (disableTrackers()) return
    beholderSetup()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Fix first page view event
  useEffect(() => {
    const userAddress = user ? getAddressFromUser(user) : undefined
    beholder.shot('trackPageView', {
      ...(user
        ? {
            user: {
              id: user.id,
              email: user.email,
              name: user.name || '',
              phoneNumber: user.phoneNumber,
              city: userAddress?.city,
              state: userAddress?.state,
              country: userAddress?.country,
              zipCode: userAddress?.zipCode,
            },
          }
        : {}),
      path: window.location.pathname,
      experimentGroup: experiment?.experimentGroup,
    })
  }, [])

  return null
}
