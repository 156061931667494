export enum Variants_Enum {
  A = 'A',
  B = 'B',
  C = 'C',
}

export interface Variant {
  id: string
  value: Variants_Enum
  weight: number
}

export interface ExperimentItem {
  description: string
  id: string
  setInitialValueInSSR: boolean
  variants: Variant[]
}

export interface Experiment {
  experimentGroup: string
  getUserExperiments: () => Record<string, Variants_Enum>
  getVariant: (experimentId: string) => Variants_Enum | null | undefined
  setVariant: (experimentId: string, variant: Variants_Enum | null) => void
  drawVariantIfUnset: (experimentId: string) => Variants_Enum | undefined
  reset: () => void
}
