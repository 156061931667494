import { useEffect, useRef } from 'react'
import { parse } from 'cookie'
import { useLojinhaContext } from '@lojinha/context'
import { beholder } from '@lojinha/beholder'
import { distributionCenterId as distributionCenterMap } from '../middleware-helpers/mapDistributionCenterByRegion'
import { getValueFromCookie } from '@lojinha/helpers'

const triggerOnboardingEvent = (
  distributionCenterId: string,
  experimentGroup?: string
) => {
  setTimeout(() => {
    const region = getValueFromCookie('_userRegion') || 'unknown'
    const city =
      getValueFromCookie('_userCity')?.replace(/\s/g, '') || 'unknown'
    const distributionCenterName =
      Object.keys(distributionCenterMap)
        .find(
          key =>
            (distributionCenterMap as Record<string, string>)[key] ===
            distributionCenterId
        )
        ?.replace(/\s/g, '') || 'unknown'

    beholder.shot('structuredEvent', {
      category: 'onboarding',
      action: 'distribution_center_received',
      label: distributionCenterId || 'unknown',
      property: `${distributionCenterName}_${city}_${region}`,
      experimentGroup,
    })
  }, 100)
}

const useOnboardingEvent = () => {
  const isOnboardingEventTriggered = useRef(false)
  const {
    centerId,
    experimentGroup,
    isContextLoaded,
    isAuthenticated,
  } = useLojinhaContext()

  useEffect(() => {
    const shouldTrigger = isAuthenticated
      ? isContextLoaded && !isOnboardingEventTriggered.current
      : !isOnboardingEventTriggered.current

    if (shouldTrigger) {
      triggerOnboardingEvent(
        centerId || parse(document.cookie)['_centerId'],
        experimentGroup
      )
      isOnboardingEventTriggered.current = true
    }
  }, [isContextLoaded, experimentGroup, centerId, isAuthenticated])

  return null
}

export { useOnboardingEvent }
