export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[]
  }
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Address: ['DeliveryAddress', 'PickupAddress'],
    CartBillingPaymentMethod: [
      'CartBillingPaymentMethodBoleto',
      'CartBillingPaymentMethodOffline',
      'CartBillingPaymentMethodOnline',
      'CartBillingPaymentMethodPix',
    ],
    ContainerComponentsDynamicZoneUnion: [
      'CMSComponentContainerAccordion',
      'CMSComponentContainerBanner',
      'CMSComponentContainerBannerBreadcrumb',
      'CMSComponentContainerButton',
      'CMSComponentContainerCallToAction',
      'CMSComponentContainerCustomHtml',
      'CMSComponentContainerDeliveryAreaCard',
      'CMSComponentContainerDisplay',
      'CMSComponentContainerHeader',
      'CMSComponentContainerImage',
      'CMSComponentContainerNavigationAnchors',
      'CMSComponentContainerProducerAreaCards',
      'CMSComponentContainerProducerCard',
      'CMSComponentContainerQuote',
      'CMSComponentContainerText',
      'CMSComponentContainerVideo',
      'CMSComponentDeliveryAreaCards',
      'CMSComponentHighlightStoryHighlightStories',
    ],
    IConsumable: ['Meal', 'Product'],
    IMarketable: ['Kit', 'Meal', 'Product'],
    INode: [
      'Cart',
      'Component',
      'Coupon',
      'DistributionCenter',
      'DynamicContainer',
      'DynamicPage',
      'DynamicPageController',
      'Kit',
      'MarketableFilter',
      'Meal',
      'Product',
      'ProductVariant',
      'Sale',
      'Store',
      'Survey',
      'SurveyAnswer',
    ],
    IPaymentMethod: [
      'BoletoPaymentMethod',
      'CreditCardPaymentMethod',
      'MarketVoucherOfflinePaymentMethod',
      'MarketVoucherOnlinePaymentMethod',
      'PixPaymentMethod',
      'RestaurantVoucherOfflinePaymentMethod',
      'RestaurantVoucherOnlinePaymentMethod',
      'TicketOnlinePaymentMethod',
    ],
    ISearchItem: ['Meal', 'Product'],
    SimpleComponent: ['Banner', 'CMSContainer', 'Display', 'HighlightStories'],
  },
}
export default result
