export function normalizeHtmlId(text = '') {
  if (!text) {
    return ''
  }
  return text
    .replace(/ /g, '-')
    .normalize('NFD')
    .replace(/([\u0300-\u036f]|[^0-9a-zA-Z-])/g, '')
    .toLocaleLowerCase()
}
