import { Address } from '@lojinha/context/src/types'
import { makeAddressId } from '@lojinha/location/src/address/logic'
import { AddressKind, User } from '@lojinha/palantir'

export const getDistributionCenterFromUser = (user?: User) => {
  if (!user) return

  const lastUserAddress = user.addresses?.nodes[0]
  if (lastUserAddress?.__typename !== 'DeliveryAddress') return

  return lastUserAddress.nearestDistributionCenter?.id
}

export const getAddressFromUser = (user?: User) => {
  if (!user) return

  const lastUserAddress = user.addresses?.nodes[0]
  if (lastUserAddress?.__typename !== 'DeliveryAddress') return

  return {
    ...lastUserAddress,
    id: makeAddressId(lastUserAddress),
    kind: AddressKind.Delivery,
  } as Address
}

export const getProfileFromUser = (user?: User) =>
  user && {
    id: user.id,
    name: user.name as string,
    email: user.email,
  }
