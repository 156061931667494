import React, { FC } from 'react'
import { theme, FontSizeProps } from '../../../../theme'

export const Alelo: FC<{
  size?: FontSizeProps
}> = ({ size }) => {
  return (
    <svg
      width={theme.fontSizes[size!]}
      height={theme.fontSizes[size!]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="2"
        y="5"
        width="20"
        height="13"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 5H22V18H2V5Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.4358 4.99993C14.0815 4.99993 12.8238 5.40653 11.7789 6.10226C11.7045 6.15201 11.1907 6.51576 11.1682 6.5328C10.315 7.16836 9.74238 7.28098 8.95742 7.0836C8.91001 7.07195 8.68508 6.99492 8.61396 6.96856C8.07765 6.76801 7.49655 6.6579 6.8894 6.6579C4.18872 6.6579 2 8.82597 2 11.4995C2 14.1744 4.18872 16.3419 6.8894 16.3419C7.43023 16.3419 7.95017 16.2537 8.43635 16.0931C9.60682 15.7464 10.242 15.8413 10.9728 16.3545C11.1194 16.4576 11.4736 16.6818 11.5462 16.7349C12.635 17.5293 13.9796 18 15.4358 18C19.0615 18 22 15.0901 22 11.4995C22 7.91012 19.0615 4.99993 15.4358 4.99993Z"
          fill="#00775D"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0679 12.504C12.8534 12.7122 12.5592 12.8393 12.2352 12.8342C12.0131 12.8311 11.8072 12.7652 11.6319 12.6554L11.1968 13.3394C11.4947 13.5252 11.8458 13.6357 12.2227 13.6413C12.7729 13.6498 13.2737 13.4356 13.6388 13.0825L13.0679 12.504Z"
        fill="#FEFEFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0932 11.8007C11.0884 11.7546 11.0851 11.7082 11.0859 11.6603C11.0957 11.0222 11.6264 10.5123 12.2712 10.5222C12.6223 10.5277 12.9338 10.6864 13.1443 10.9326L11.0932 11.8007ZM12.2835 9.71526C11.189 9.69906 10.2876 10.5642 10.271 11.6483C10.2647 12.0543 10.3834 12.4336 10.5918 12.7498L14.1839 11.2303C13.9814 10.3735 13.2126 9.72979 12.2835 9.71526Z"
        fill="#FEFEFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3096 8.83093V12.6217L15.9734 12.8942L15.6602 13.6414L15.0031 13.3711C14.8543 13.3085 14.7549 13.211 14.6797 13.1017C14.6061 12.9906 14.552 12.8375 14.552 12.6318V8.83093H15.3096Z"
        fill="#FEFEFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.52937 8.83093V12.6217L10.1931 12.8942L9.87999 13.6414L9.22288 13.3711C9.07424 13.3085 8.97471 13.211 8.89917 13.1017C8.82579 12.9906 8.77161 12.8375 8.77161 12.6318V8.83093H9.52937Z"
        fill="#FEFEFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5781 10.6361C19.1599 10.9128 19.4044 11.6034 19.1249 12.1792L19.8595 12.5285C20.3341 11.5514 19.9186 10.3786 18.9313 9.90866C18.1814 9.55171 17.3173 9.70401 16.7377 10.2266L17.2867 10.8241C17.6281 10.516 18.1369 10.4256 18.5781 10.6361Z"
        fill="#C6D44D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5666 12.7203C16.9859 12.4435 16.7409 11.7527 17.0199 11.1776V11.1773L16.2854 10.8277C15.8114 11.8052 16.2264 12.9781 17.2133 13.4479C17.964 13.8048 18.8276 13.6522 19.4076 13.1295L18.8584 12.5327C18.5167 12.8402 18.0084 12.9304 17.5666 12.7203Z"
        fill="#FEFEFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.02396 12.7749V11.6828V11.6777C8.02396 10.802 7.44446 10.0615 6.64446 9.8101C6.45546 9.75077 6.25424 9.71788 6.04511 9.7177C5.84012 9.71788 5.64238 9.749 5.45649 9.80591C5.1468 9.90139 4.86956 10.0703 4.64576 10.2919C4.28753 10.6464 4.06627 11.1366 4.0658 11.6777C4.06627 12.76 4.95207 13.6369 6.04511 13.6373H6.04605C6.36214 13.6373 6.66233 13.5627 6.92837 13.4311L6.60795 12.6918C6.44079 12.7829 6.25047 12.8352 6.04511 12.8352C5.39948 12.8342 4.877 12.3166 4.8754 11.6777H4.87577C4.87624 11.4811 4.9261 11.2965 5.01312 11.1346C5.17004 10.8435 5.44868 10.6273 5.78152 10.5507C5.86646 10.5311 5.95424 10.5196 6.04492 10.5196C6.21783 10.52 6.38142 10.5582 6.52874 10.6248C6.93242 10.8076 7.2136 11.2094 7.21436 11.6777H7.21483C7.21483 11.7107 7.21483 12.7657 7.21511 12.7657C7.21511 12.9711 7.26939 13.1247 7.34239 13.2355C7.41774 13.3448 7.51718 13.4424 7.66591 13.5052L8.03703 13.6509L8.35049 12.9038L8.02396 12.7749Z"
        fill="#FEFEFE"
      />
    </svg>
  )
}

Alelo.defaultProps = {
  size: 'x3',
}
