export enum distributionCenterId {
  RioComprido = '60c76421a1dfc196eafa9358',
  Saude = '60c7642aa1dfc196eafa9790',
  Campinas = '5c1bd7c8b9760167cc09da69',
  Brasilia = '5cc3797b69c8c48d7a767db5',
  BeloHorizonte = '5b1eac44e3767bfdd4b7b2d8',
  PortoAlegre = '5cd2e32769c8c48d7ac0c301',
  Curitiba = '5c640cac22fad440b21837db',
  Florianopolis = '5e34870d46076c16cf347e0a',
  Fortaleza = '5da637a5b15d9955a5015b64',
  Goiania = '5cb5eef669c8c48d7a52a391',
  Recife = '5da639aeb15d9955a5015b65',
  Salvador = '5e31b0991e417877d5ae84f1',
  SaoJoseDosCampos = '5cf847c67daa4f84ffdb93fd',
  RibeiraoPreto = '5cc9a88769c8c48d7afdd772',
  Santos = '5cd2e77e69c8c48d7ac10cc3',
  Sorocaba = '5cf847e37daa4f84ffdb9644',
}

export const mapDistributionCenterIdByGeo: {
  [key: string]: { [key: string]: string } | string
} = {
  SP: {
    'São Paulo': distributionCenterId.Saude,
    Diadema: distributionCenterId.Saude,
    Guarulhos: distributionCenterId.Saude,
    'São Bernardo do Campo': distributionCenterId.Saude,
    'Santo André': distributionCenterId.Saude,
    'São Caetano do Sul': distributionCenterId.Saude,
    Cotia: distributionCenterId.Saude,
    Barueri: distributionCenterId.Saude,
    Osasco: distributionCenterId.Saude,
    'Taboão da Serra': distributionCenterId.Saude,
    Campinas: distributionCenterId.Campinas,
    Jundiaí: distributionCenterId.Campinas,
    Valinhos: distributionCenterId.Campinas,
    Vinhedo: distributionCenterId.Campinas,
    Hortolândia: distributionCenterId.Campinas,
    Paulínia: distributionCenterId.Campinas,
    'São José dos Campos': distributionCenterId.SaoJoseDosCampos,
    'Ribeirão Preto': distributionCenterId.RibeiraoPreto,
    Santos: distributionCenterId.Santos,
    Sorocaba: distributionCenterId.Sorocaba,
  },
  RJ: distributionCenterId.RioComprido,
  DF: distributionCenterId.Brasilia,
  MG: distributionCenterId.BeloHorizonte,
  RS: distributionCenterId.PortoAlegre,
  SC: distributionCenterId.Florianopolis,
  PR: distributionCenterId.Curitiba,
  CE: distributionCenterId.Fortaleza,
  GO: distributionCenterId.Goiania,
  PE: distributionCenterId.Recife,
  BA: distributionCenterId.Salvador,
}
