export const generateAddressLabel = ({
  state,
  city,
  neighborhood,
  zipCode,
}: {
  state?: string
  city?: string
  neighborhood?: string
  zipCode?: string
}) => `${state}_${city}_${neighborhood}_${zipCode}`
