import { beholder } from './beholder'

export const setupInsiderUserObject = () => {
  beholder.watch('setUser', ({ id, email }) => {
    window.insider_object = window.insider_object || {}
    window.insider_object.user = {
      email,
      uuid: id,
      gdpr_optin: true,
    }
    window.Insider?.eventManager.dispatch('init-manager:re-initialize')
  })
}
