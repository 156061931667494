import dayjs from 'dayjs'

import { Address, BagProps } from '@lojinha/context/src/types'
import { getLocalStorage, removeLocalStorage } from '@lojinha/helpers'
import { makeAddressId } from '@lojinha/location/src/address/logic'
import { DeliveryStatus } from '@lojinha/palantir'

export const isBagExpired = (createdAt?: Date): boolean => {
  if (createdAt === undefined) {
    return true
  }

  const now = dayjs()
  const diffInDays = now.diff(createdAt, 'day')

  return diffInDays > 3
}

export const getLocalBag = (): BagProps | undefined => {
  const bagContent = JSON.parse(getLocalStorage('localBag') || '{}')
  const bag = (bagContent || {}) as BagProps

  if (isBagExpired(bag?.createdAt)) {
    removeLocalStorage('localBag')
    return
  }

  return bag
}

export const parseAddresses = (allUserAddresses: any[]) => {
  const addressesResult: Address[] = []

  allUserAddresses.forEach(address => {
    if (address.__typename === 'DeliveryAddress') {
      const parsedAddress = {
        ...address,
        id: makeAddressId(address),
      } as Address
      addressesResult.push(parsedAddress)
    }
  })

  return addressesResult.filter((address, index) => {
    const _addressCoordinates = JSON.stringify(address.coordinates)
    const _adressComplement = address.complement
    return (
      index ===
      addressesResult.findIndex(obj => {
        if (_adressComplement) {
          return (
            JSON.stringify(obj.coordinates) === _addressCoordinates &&
            obj.complement == _adressComplement
          )
        } else {
          return JSON.stringify(obj.coordinates) === _addressCoordinates
        }
      })
    )
  })
}

export function isReturnedStatus(deliveryStatus: DeliveryStatus) {
  return [DeliveryStatus.FailedToDeliver, DeliveryStatus.Returned].includes(
    deliveryStatus
  )
}
