import React, { FC } from 'react'
import { theme, FontSizeProps } from '../../../../theme'

export const Amex: FC<{
  size?: FontSizeProps
}> = ({ size }) => {
  return (
    <svg
      width={theme.fontSizes[size!]}
      height={theme.fontSizes[size!]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="2" y="5" width="20" height="14" rx="2" fill="#016FD0" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.23652 10.5V13.9995H9.10789V11.4209L10.0341 13.9995H10.7983L11.722 11.4209V13.9995H12.5934V10.5H11.2175L10.4302 12.7615L9.62346 10.5052L8.23652 10.5ZM5.50549 10.5004L4 13.9999H4.98015L5.25794 13.2992H6.87281L7.14916 13.9999H8.1509L6.64684 10.5004H5.50549ZM6.05839 11.3151L6.54965 12.5396H5.5657L6.05839 11.3151ZM13.1896 13.9995V10.5H16.0273V11.2828H14.0684V11.8814H15.9816V12.6181H14.0684V13.2398H16.0273V13.9995H13.1896ZM17.5736 12.2714L16.1892 13.9995H17.2712L18.0709 12.9218L18.8906 13.9995H19.9882L18.6012 12.2498L20 10.5H18.9452L18.0982 11.595L17.254 10.5H16.1562L17.5736 12.2714Z"
        fill="white"
      />
    </svg>
  )
}

Amex.defaultProps = {
  size: 'x3',
}
