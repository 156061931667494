import 'styled-components'
import { DefaultTheme } from 'styled-components'
import { breakpoints, media, windowSizesPx } from './media'

/*
  Colors
*/
const colors = require('../assets/colors.json')
export const baseColors = {
  /** white - use to background or negative text */
  white: '#ffffff',
  /** sand - use to background */
  gray100: '#F6F3EA',
  /** ice - use to divisors */
  gray200: '#ECECEC',
  /** inox - use to disabled */
  gray300: '#D3D3D3',
  /** graphite - use to secondary text or support information */
  gray700: '#5B5B5F',
  /** black - use to title of page or main information */
  black: '#000000',

  /** tangerine - primary color */
  primary: '#F15A22',
  /** lemon -  positive  */
  success: '#0C893E',
  /** cherry -  negative  */
  error: '#D9224A',
  /** mango - caution */
  alert: '#FCBB14',
  /** mango - caution */
  warning: '#F59E1B',

  currentColor: 'currentColor',
  inherit: 'inherit',
  transparent: 'transparent',

  // secondary

  kiwiLight: '#00A995',
  kiwiLightSupport: '#00A995',
  kiwiDark: '#00332E',
  kiwiDarkSupport: '#00332E',

  mangoLight: '#FCBB14',
  mangoLightSupport: '#FFE7A9',
  mangoExtraLight: '#FFF9E9',
  mangoDark: '#B2611A',
  mangoDarkSupport: '#42190A',

  grapeLight: '#623F99',
  grapeLightSupport: '#D2C2FF',
  grapeDark: '#432A74',
  grapeDarkSupport: '#B293EC',

  blueberryLight: '#00B1C5',
  blueberryLightSupport: '#B3F0FF',
  blueberryDark: '#00718A',
  blueberryDarkSupport: '#003B42',

  acaiLight: '#B41E8E',
  acaiLightSupport: '#FBD5F2',
  acaiDark: '#651D79',
  acaiDarkSupport: '#E68ECD',

  // old

  blue: '#00c0e0',
  brown: '#5d2318',
  ocean: '#006687',
}

export const colorShades = {
  hover: 0.16,
  pressed: 0.32,
}

type ColorShade = {
  type: keyof typeof colorShades
  color: ColorProps
}

export const colorShade = ({ type, color }: ColorShade) => {
  return colors[`${color}:${type}`]
}

/*
  Typography
*/
const fallbackFontStack =
  '-apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'

const fonts = {
  body: `"Hind Madurai", ${fallbackFontStack}`,
  brand: `"Amsi Pro Normal", "Hind Madurai", ${fallbackFontStack}`,
}

const fontSizes = {
  /** 10px */
  x0: '0.625rem',
  /** 12px */
  x1: '0.750rem',
  /** 16px */
  x2: '1.000rem',
  /** 24px */
  x3: '1.500rem',
  /** 32px */
  x4: '2.000rem',
  /** 40px */
  x5: '2.500rem',
  /** 48px */
  x6: '3.000rem',
  /** 12px */
  x12: '0.75rem',
  /** 14px */
  x14: '0.875rem',
  /** 20px */
  x20: '1.250rem',
}

const fontWeights = {
  bold: 700,
  normal: 400,
}

const lineHeights = {
  /** 10px */
  x0: '0.625rem',
  /** 16px */
  x1: '1.000rem',
  /** 24px */
  x2: '1.500rem',
  /** 32px */
  x3: '2.000rem',
  /** 40px */
  x4: '2.500rem',
  /** 48px */
  x5: '3.000rem',
  /** 56px */
  x6: '3.500rem',
  /** 16px */
  x16: '1rem',
  /** 18px */
  x18: '1.125rem',
  /** 24px */
  x24: '1.500rem',
}

const textSizes = {
  x0: {
    fontSize: fontSizes.x0,
    lineHeight: lineHeights.x0,
  },
  x1: {
    fontSize: fontSizes.x1,
    lineHeight: lineHeights.x1,
  },
  x2: {
    fontSize: fontSizes.x2,
    lineHeight: lineHeights.x2,
  },
  x3: {
    fontSize: fontSizes.x3,
    lineHeight: lineHeights.x3,
  },
  x4: {
    fontSize: fontSizes.x4,
    lineHeight: lineHeights.x4,
  },
  x5: {
    fontSize: fontSizes.x5,
    lineHeight: lineHeights.x5,
  },
  x6: {
    fontSize: fontSizes.x6,
    lineHeight: lineHeights.x6,
  },
  x12: {
    fontSize: fontSizes.x12,
    lineHeight: lineHeights.x16,
  },
  x14: {
    fontSize: fontSizes.x14,
    lineHeight: lineHeights.x18,
  },
  x20: {
    fontSize: fontSizes.x20,
    lineHeight: lineHeights.x24,
  },
}

/*
  Shadows
*/
const shadows = {
  /** 0% */
  x0: 'none',
  /** 16% opacity gray */
  gray16: '0 0 8px 0 rgba(0, 0, 0, 0.16)',
  /** 24% opacity gray */
  gray24: '0 0 8px 0 rgba(0, 0, 0, 0.24)',
  /** 32% opacity gray */
  gray32: '0 0 8px 0 rgba(0, 0, 0, 0.32)',

  /** 32% opacity tangerine */
  primary32: '0px 0px 8px rgba(241, 90, 34, 0.32)',
}

/*
  Spacing
*/
const space = {
  /** 0px */
  x0: '0.000rem',
  /** 2px */
  x2: '0.125rem',
  /** 4px */
  x4: '0.250rem',
  /** 6px */
  x6: '0.375rem',
  /** 8px */
  x8: '0.500rem',
  /** 12px */
  x12: '0.75rem',
  /** 13px */
  x13: '0.8125rem',
  /** 14px */
  x14: '0.875rem',
  /** 16px */
  x16: '1.000rem',
  /** 20px */
  x20: '1.250rem',
  /** 24px */
  x24: '1.500rem',
  /** 32px */
  x32: '2.000rem',
  /** 40px */
  x40: '2.500rem',
  /** 46px */
  x46: '2.875rem',
  /** 48px */
  x48: '3.000rem',
  /** 56px */
  x56: '3.500rem',
  /** 64px */
  x64: '4.000rem',
  /** 72px */
  x72: '4.500rem',
  /** 80px */
  x80: '5.000rem',
  /** 88px */
  x88: '5.500rem',
  /** 96px */
  x96: '6.000rem',
  /** 100px */
  x100: '6.000rem',
  /** 120px */
  x120: '7.500rem',
  /** 128px */
  x128: '8.000rem',
  /** 160px */
  x160: '10.000rem',
  /** 256px */
  x256: '16.000rem',
}

/*
  Border radius
*/
const borderRadius = {
  /** 0px */
  x0: '0',
  /** 2px */
  x2: '0.125rem',
  /** 4px */
  x4: '0.250rem',
  /** 8px */
  x8: '0.500rem',
  /** 48px */
  x48: '3.000rem',
  /** 100px */
  x100: '6.000rem',
  /** 50% */
  xHalf: '50%',
  /** 100% */
  xFull: '100%',
}

export type ColorProps = keyof typeof baseColors
export type FontProps = keyof typeof fonts
export type FontSizeProps = keyof typeof fontSizes
export type FontWeightProps = keyof typeof fontWeights
export type LineHeightProps = keyof typeof lineHeights
export type ShadowProps = keyof typeof shadows
export type SpaceProps = keyof typeof space
export type TextSizeProps = keyof typeof textSizes
export type BorderRadius = keyof typeof borderRadius

declare module 'styled-components' {
  export interface DefaultTheme {
    colors: typeof baseColors
    fonts: typeof fonts
    fontSizes: typeof fontSizes
    fontWeights: typeof fontWeights
    lineHeights: typeof lineHeights
    shadows: typeof shadows
    space: typeof space
    textSizes: typeof textSizes
    breakpoints: typeof breakpoints
    media: typeof media
    windowSizesPx: typeof windowSizesPx
    colorShade: typeof colorShade
    borderRadius: typeof borderRadius
  }
}

export const theme: DefaultTheme = {
  colors,
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  shadows,
  space,
  textSizes,
  breakpoints,
  media,
  windowSizesPx,
  colorShade,
  borderRadius,
}

export type Theme = typeof theme
