import React, { FC } from 'react'
import { theme, FontSizeProps } from '../../../../theme'

export const VrFood: FC<{
  size?: FontSizeProps
}> = ({ size }) => {
  return (
    <svg
      width={theme.fontSizes[size!]}
      height={theme.fontSizes[size!]}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 21H21V3.00002H3V21Z"
        fill="#109332"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 21H21V17H3V21Z"
        fill="#D0009D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 13.75H14.7616L13.642 9.62933H14.4432C14.603 9.64418 15.2174 9.40686 15.2155 8.81428C15.2137 8.27666 14.7112 8.28795 14.5344 8.28052H13.5358L11.3718 13.75H8.16371L6 7.96923H8.648L9.6921 11.0375L10.933 6.25002H15.6771C16.9598 6.25002 18 7.32171 18 8.64379C18 9.54111 17.5206 10.3235 16.8116 10.7331L18 13.75Z"
        fill="#FEFEFE"
      />
    </svg>
  )
}

VrFood.defaultProps = {
  size: 'x3',
}
