import {
  adaptUserToFbPixelUser,
  FbPixelUser,
} from '../helpers/adaptUserToFbPixelUser'
import { beholder } from './beholder'

export const facebookPixelScript = `
  !function(f,b,e,v,n,t,s) {
    if (f.fbq) {
      return;
    };
    n = f.fbq = function() {
      n.callMethod ? n.callMethod.apply(n,arguments) : n.queue.push(arguments);
    };
    if(!f._fbq) {
      f._fbq = n;
    };
    n.push = n;
    n.loaded = true;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = true;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s)
  } (window, document, 'script', 'https://connect.facebook.net/pt_BR/fbevents.js');
`

function initFacebookPixel(userData: FbPixelUser) {
  window.fbq?.('init', process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID, userData)
}

export function facebookTrackerSetup() {
  beholder.watch('setUser', user => {
    if (typeof window.fbq !== 'function') {
      console.warn('Facebook pixel not loaded')
      return
    }
    const userData = adaptUserToFbPixelUser(user)
    initFacebookPixel(userData)
  })

  beholder.watch('trackPageView', ({ user }) => {
    if (typeof window.fbq !== 'function') {
      console.warn('Facebook pixel not loaded')
      return
    }
    const userData = adaptUserToFbPixelUser(user)
    initFacebookPixel(userData)
    window.fbq?.('track', 'PageView')
  })
}
