import {
  ApolloClient,
  from,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { IS_SERVER } from '@lojinha/helpers'
import fetch from 'cross-fetch'
import { breadcrumbsLink, errorLink } from './sentryLink'
import { getAuthToken } from './authToken'
import { AppWithApolloCtx } from './apolloTypes'
import { cacheConfig } from './cache'

const palantirLink = new HttpLink({
  uri: process.env.NEXT_PUBLIC_PALANTIR_GRAPHQL_URL,
  fetch,
})

const getAuthLink = (ctx?: AppWithApolloCtx) =>
  setContext((_, { headers }) => {
    const token = getAuthToken()
    return {
      headers: {
        authorization: ctx?.authToken || token,
        ...headers,
      },
    }
  })

export const initApollo = (
  initialState: NormalizedCacheObject,
  ctx?: AppWithApolloCtx
): ApolloClient<NormalizedCacheObject> =>
  createApolloClient(initialState, IS_SERVER ? ctx : undefined)

function createApolloClient(
  initialState: NormalizedCacheObject = {},
  ctx?: AppWithApolloCtx
) {
  const authLink = getAuthLink(ctx)
  return new ApolloClient({
    name: 'Lojinha',
    connectToDevTools: process.env.NEXT_PUBLIC_SITE_ENV !== 'production',
    ssrMode: IS_SERVER,
    link: from([breadcrumbsLink, errorLink, authLink, palantirLink]),
    cache: new InMemoryCache(cacheConfig).restore(initialState),
  })
}
