import { beholder } from './beholder'

export const googleAnalyticsScript = `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}');
`

const convertToGoogleAnalyticsCurrency = (value: number) => {
  return (value / 100).toFixed(2)
}

export const googleAnalyticsTrackerSetup = () => {
  beholder.watch('productDetailView', payload => {
    const item = {
      ...payload,
      price: convertToGoogleAnalyticsCurrency(payload.price),
    }

    window.gtag('event', 'view_item', {
      currency: 'BRL',
      value: item.price,
      items: [
        {
          item_id: item.id,
          item_name: item.name,
          index: item.positionInList,
          item_list_name: item.listName,
          price: item.price,
        },
      ],
    })
  })
}
